import { useState } from 'react';
import { useAuthContext } from './useAuthContext';
import { useNavigate } from 'react-router-dom';

export const useSignup = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [errorLogin, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(null);
	const { dispatch } = useAuthContext();
	const navigate = useNavigate();

	const signup = async (firstName, lastName, email, password, numberMobile, active) => {
		setIsLoading(true);
		setError(null);

		const response = await fetch(`${API_URL}/api/client/signupClient`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ firstName, lastName, email, password, numberMobile, active }),
		});
		const json = await response.json();

		if (!response.ok) {
			setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
			// save the user to local storage
			localStorage.setItem('client', JSON.stringify(json));

			// update the auth context
			dispatch({ type: 'LOGIN', payload: json });
			navigate('/send-otp');
			// update loading state
			setIsLoading(false);
		}
	};

	return { signup, isLoading, errorLogin };
};
