import { useState } from 'react';
import axios from 'axios';
import WhiteLogo from '../../components/Logo/WhiteLogo';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavLink, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';

function EmailVerification({ setIsVerified }) {
	const API_URL = process.env.REACT_APP_API_URL;
	const validationSchema = Yup.object().shape({
		otp: Yup.string().required('Otp is required'),
	});
	const navigate = useNavigate();
	const formOptions = { resolver: yupResolver(validationSchema) };
	const { register, handleSubmit, formState, reset } = useForm(formOptions);
	const { errors } = formState;
	const [error, setError] = useState('');
	const [emailVerified, setEmailVerified] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState('Signing you in');

	const onSubmit = (data) => {
		setIsLoading(true);
		axios
			.post(`${API_URL}/api/client/emailverified-otp`, {
				otp: data.otp,
				emailVerified: emailVerified,
			})
			.then((res) => {
				console.log(res.data);
				if (res.data.code === 200) {
					if (res.data.message.includes('true')) {
						console.log('YESSSSSSSSS');
						setIsVerified(true);
					}
					navigate('/login');
				} else {
					setError('Wrong Otp');
				}
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setIsLoading(false);
				setError(err.message);
			});
	};

	return (
		<div className='login-page'>
			<div className='login-wrapper'>
				<div className='login-logo'>
					<WhiteLogo />
				</div>
				<div className='details'>
					<h3 className='title'>Email verification</h3>
					<p className='text'>We have sent you an email with an OTP to verify your account.</p>
					<ol className='steps'>
						<li>Get the OTP from your Email.</li>
						<li>Type it in the input feild below.</li>
						<li>Click on Verify Account button.</li>
					</ol>
				</div>
				<form className='login-form' onSubmit={handleSubmit(onSubmit)}>
					<div className='form-group'>
						<input type='text' className='form-control' placeholder='Otp' {...register('otp')} />
					</div>
					<span className='errorValidation'>{errors.otp?.message}</span>

					<button type='submit' className='login-btn'>
						Verify Account
					</button>
					{error && <span className='errorValidation'>{error}</span>}

					<p className='sign-up-link'>
						Already have an account ? <NavLink to='/login'>Login</NavLink>
					</p>
				</form>
			</div>
			{isLoading && <Loading loadingMessage={loadingMessage} />}
		</div>
	);
}

export default EmailVerification;
