import React from 'react';
import TopBar from '../TopBar/TopBar';

const Layout = ({ isMobile, children, language, menuItems, handleLanguageChange, user }) => {
	return (
		<React.Fragment>
			<TopBar
				isMobile={isMobile}
				language={language}
				menuItems={menuItems}
				handleLanguageChange={handleLanguageChange}
				user={user}
			/>
			{children}
		</React.Fragment>
	);
};

export default Layout;
