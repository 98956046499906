import React from 'react';
import AppsICon from '../../assets/SVG/AppsICon';
import { useNavigate } from 'react-router-dom';
import './Success.scss';

const Success = ({ text }) => {
	const navigate = useNavigate();
	return (
		<div className='contact-success'>
			<div className='icon'>
				<svg className='checkmark' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
					<circle className='checkmark__circle' cx='26' cy='26' r='25' fill='none' />
					<path className='checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
				</svg>
			</div>
			<div className='info'>
				<h1>{text?.title}</h1>
				<p>{text?.message}</p>
				<div className='navigate' role='button' onClick={() => navigate('/applications')}>
					<AppsICon />
					<span>{text?.btn_text}</span>
				</div>
			</div>
		</div>
	);
};

export default Success;
