import './Prompt.scss';

const Prompt = ({ promptRejectAction, promptAcceptAction, prompText }) => {
	return (
		<div className='prompt'>
			<div className='prompt-container'>
				<div className='prompt-content'>
					<div className='content-title'>
						<span>{prompText.title}</span>
					</div>
					<div className='content-text'>
						<p>{prompText.message}</p>
						<p className='content-question'>{prompText.question}</p>
					</div>
					<div className='prompt-actions'>
						<div className='action accept' role='button' onClick={promptAcceptAction}>
							<span>{prompText.accept_button_text}</span>
						</div>
						<div className='action reject' role='button' onClick={promptRejectAction}>
							<span>{prompText.reject_button_text}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Prompt;
