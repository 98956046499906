import { createContext, useReducer, useEffect } from 'react'

export const AuthContext = createContext()

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { client: action.payload }
    case 'LOGOUT':
      return { client: null }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    client: null
  })

  useEffect(() => {
    const client = JSON.parse(localStorage.getItem('client'))

    if (client) {
      dispatch({ type: 'LOGIN', payload: client })
    }
  }, [])

  // console.log('AuthContext state:', state)

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )

}