import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Navigate } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import Layout from './containers/Layout/Layout';
import Landing from './pages/Landing/Landing';
import InteiorDesignApp from './pages/InteiorDesignApp/InteiorDesignApp';
import ExteriorDesignApp from './pages/ExteriorDesignApp/ExteriorDesignApp';
import Login from './pages/ClientSignin/Login';
import Signup from './pages/ClientSignup/Signup';
import Results from './pages/Results/Results';
import Profile from './pages/Profile/Profile';
import PaymentSuccess from './pages/Payment/PaymentSuccess';
import PaymentCancel from './pages/Payment/PaymentCancel';
// import WaitingList from './pages/WaitingList/WaitingList';
import Applications from './pages/Applications/Applications';
import Pricing from './pages/Pricing/Pricing';
import Contact from './pages/Contact/Contact';
import { useAuthContext } from './hooks/useAuthContext';
import axios from 'axios';
// import { questionsData } from './resources/questionsData';
import { getTranslations } from './i18n/getTranslations';
import './App.scss';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import SendOTP from './pages/SendOTP/SendOTP';
import EmailVerification from './pages/EmailVerification/EmailVerification';

const App = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const { client } = useAuthContext();

	const [user, setUser] = useState({});
	console.log('PPE 18');
	const [interiorAnswers, setInteriorAnswers] = useState({
		roomType: { id: '', text: '' },
		designStyle: { id: '', text: '' },
		generalMood: { id: '', text: '' },
		artWork: { id: '', text: '' },
		primaryColor: { id: '', text: '' },
		secondaryColor: { id: '', text: '' },
		flooring: { id: '', text: '' },
		wallCladding: { id: '', text: '' },
	});

	const [exteriorAnswers, setExteriorAnswers] = useState({
		buildingType: { id: '', text: '' },
		floorsNumber: { id: '', text: '' },
		archStyle: { id: '', text: '' },
		color: { id: '', text: '' },
		primaryMaterial: { id: '', text: '' },
		secondaryMaterial: { id: '', text: '' },
		surroundings: { id: '', text: '' },
		landscape: { id: '', text: '' },
	});

	//FIXME: move to .env
	const allowedNumberOfSubmits = parseInt(process.env.REACT_APP_DEFAULT_NBR_OF_SUBMITS);

	const [history, setHistory] = useState([]);
	const [APIResponse, setAPIResponse] = useState([]);
	const [isMobile, setIsMobile] = useState(false);
	const [dataCleint, setDataCleint] = useState({});
	const [numberOfSubmits, setNumberOfSubmits] = useState('');
	const [isActiveUser, setIsActiveUser] = useState(
		sessionStorage.getItem('isActive-client')
			? JSON.parse(sessionStorage.getItem('isActive-client'))
			: false,
	);

	const [isVerified, setIsVerified] = useState(false);

	const [interiorResultFormAnswers, setInteriorResultFormAnswers] = useState({});
	const [exteriorResultFormAnswers, setExteriorResultFormAnswers] = useState({});

	const [language, setLanguage] = useState(
		sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'EN',
	);
	const [selectedLanguage, setSelectedLanguage] = useState(getTranslations(language));

	const [interiorQuestionsData, setInteriorQuestionsData] = useState([]);
	const [exteriorQuestionsData, setExteriorQuestionsData] = useState([]);

	const [isPromptDisplayed, setIsPromptDisplayed] = useState(false);
	const [appID, setAppID] = useState(
		sessionStorage.getItem('appID') ? sessionStorage.getItem('appID') : 'interior',
	);
	const [itemsList, setItemsList] = useState([
		{ id: 0, text: 'Free', selected: false },
		{ id: 1, text: 'Basic', selected: false },
		{ id: 2, text: 'Pro', selected: true },
		{ id: 3, text: 'Teams', selected: false },
	]);

	const getUser = (id) => {
		axios.get(`${API_URL}/api/client/clients/${id}`).then((res) => {
			if (res.data.dataclient != 0) {
				sessionStorage.setItem('isActive-client', res.data.data.active);
				setIsActiveUser(res.data.data.active);
				setUser(res.data.data);
			} else {
				return;
			}
		});
	};

	const getDataClient = (id) => {
		axios.get(`${API_URL}/api/data/dataClients/client/${id}`).then((res) => {
			if (res.data.dataclient != 0) {
				setNumberOfSubmits(res.data.dataclient[0].nbSubmit);
				sessionStorage.setItem('isActive-client', res.data.dataclient[0].user.active);
				setIsActiveUser(res.data.dataclient[0].user.active);
				setDataCleint(res.data.dataclient[0].user);
			} else {
				return;
			}
		});
	};

	const mobileCheck = () => {
		let check = false;
		(function (a) {
			if (
				/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
					a,
				) ||
				/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
					a.substr(0, 4),
				)
			)
				check = true;
		})(navigator.userAgent || navigator.vendor || window.opera);
		return check;
	};

	const constructSelectOptions = () => {
		let selectInfo = {};
		let questions = [];
		if (appID.includes('interior')) {
			selectInfo = { ...interiorResultFormAnswers };
			questions = [...interiorQuestionsData];
			questions.forEach((question) => {
				for (const key in interiorAnswers) {
					const element = interiorAnswers[key];
					if (question.id === key) {
						const selectedChoice = question.choices.filter((choice) => choice.id === element.id)[0];
						selectInfo[key] = {
							labelText: question.title,
							options: question.choices,
							selected: selectedChoice,
						};
					}
				}
			});
			setInteriorResultFormAnswers(selectInfo);
		} else if (appID.includes('exterior')) {
			selectInfo = { ...exteriorResultFormAnswers };
			questions = [...exteriorQuestionsData];
			questions.forEach((question) => {
				for (const key in exteriorAnswers) {
					const element = exteriorAnswers[key];
					if (question.id === key) {
						const selectedChoice = question.choices.filter((choice) => choice.id === element.id)[0];
						selectInfo[key] = {
							labelText: question.title,
							options: question.choices,
							selected: selectedChoice,
						};
					}
				}
			});
			setExteriorResultFormAnswers(selectInfo);
		}
	};

	const handleLanguageChange = (e) => {
		const id = e.target.id;
		const pathname = window.location.pathname;
		if (pathname === '/results') {
			setIsPromptDisplayed(true);
		} else if (id === 'lang-en') {
			setLanguage('EN');
			sessionStorage.setItem('lang', 'EN');
		} else if (id === 'lang-ar') {
			setLanguage('AR');
			sessionStorage.setItem('lang', 'AR');
		}
	};

	useEffect(() => {
		if (isVerified) {
			getUser(client.id);
		} else {
			setUser({});
		}
	}, [isVerified]);

	useEffect(() => {
		if (client) {
			getUser(client.id);
			getDataClient(client.id);
		} else {
			setUser({});
		}
	}, [client]);

	useEffect(() => {
		setInteriorQuestionsData(selectedLanguage.interior_data.interior_questions_data);
	}, [selectedLanguage.interior_data.interior_questions_data[0].text]);

	useEffect(() => {
		setExteriorQuestionsData(selectedLanguage.exterior_data.exterior_questions_data);
	}, [selectedLanguage.exterior_data.exterior_questions_data[0].text]);

	useEffect(() => {
		setSelectedLanguage(getTranslations(language));
	}, [language]);

	useEffect(() => {
		if (appID === 'comm_interior') {
			const { interior_commercial_room_type, interior_questions_data } =
				selectedLanguage.interior_data;
			const interiorQuestions = [...interior_questions_data];
			interiorQuestions.splice(0, 1, interior_commercial_room_type);
			setInteriorQuestionsData(interiorQuestions);
		} else if (appID === 'interior') {
			setInteriorQuestionsData(selectedLanguage.interior_data.interior_questions_data);
		} else if (appID === 'comm_exterior') {
			const { exterior_commercial_building_type, exterior_questions_data } =
				selectedLanguage.exterior_data;
			const exteriorQuestions = [...exterior_questions_data];
			exteriorQuestions.splice(0, 1, exterior_commercial_building_type);
			setExteriorQuestionsData(exteriorQuestions);
		} else if (appID === 'exterior') {
			setExteriorQuestionsData(selectedLanguage.exterior_data.exterior_questions_data);
		}
	}, [appID]);

	useEffect(() => {
		const isMobileDevice = mobileCheck();
		setIsMobile(isMobileDevice);
		if (!isMobileDevice) {
			const clonedItemsList = [...itemsList];
			clonedItemsList.map((item) => (item.selected = true));
			setItemsList(clonedItemsList);
		}
	}, []);

	return (
		<div className='interior-ai-app'>
			<div className='ai-app-container'>
				<div className='ai-app'>
					<Router>
						<Layout
							isMobile={isMobile}
							user={user}
							language={language}
							setLanguage={setLanguage}
							menuItems={selectedLanguage.menu_items}
							handleLanguageChange={handleLanguageChange}>
							<Routes>
								<Route
									path='/'
									element={
										<Landing
											numberOfSubmits={parseInt(numberOfSubmits)}
											allowedNumberOfSubmits={allowedNumberOfSubmits}
											isMobile={isMobile}
											client={client}
											isActiveUser={isActiveUser}
											pageText={selectedLanguage.landing_general_text}
										/>
									}
								/>
								<Route
									path='/applications/interior-design'
									element={
										user.emailVerified ? (
											<InteiorDesignApp
												interiorQuestionsData={interiorQuestionsData}
												appID={appID}
												answers={interiorAnswers}
												setAnswers={setInteriorAnswers}
												history={history}
												setHistory={setHistory}
												APIResponse={APIResponse}
												setAPIResponse={setAPIResponse}
												isMobile={isMobile}
												allowedNumberOfSubmits={allowedNumberOfSubmits}
												isActiveUser={isActiveUser}
												constructSelectOptions={constructSelectOptions}
												questionsNavList={selectedLanguage.interior_data.interior_nav_items}
												pageText={selectedLanguage.common}
											/>
										) : (
											<Navigate to='/login' />
										)
									}
								/>
								<Route
									path='/applications/exterior-design'
									element={
										user.emailVerified && (
											<ExteriorDesignApp
												exteriorQuestionsData={exteriorQuestionsData}
												setExteriorQuestionsData={setExteriorQuestionsData}
												appID={appID}
												answers={exteriorAnswers}
												setAnswers={setExteriorAnswers}
												history={history}
												setHistory={setHistory}
												APIResponse={APIResponse}
												setAPIResponse={setAPIResponse}
												isMobile={isMobile}
												allowedNumberOfSubmits={allowedNumberOfSubmits}
												isActiveUser={isActiveUser}
												interiorResultFormAnswers={interiorResultFormAnswers}
												setInteriorResultFormAnswers={setInteriorResultFormAnswers}
												constructSelectOptions={constructSelectOptions}
												questionsNavList={selectedLanguage.exterior_data.exterior_nav_items}
												pageText={selectedLanguage.common}
											/>
										)
									}
								/>
								<Route
									path='/login'
									element={
										<Login
											answers={interiorAnswers}
											history={history}
											setHistory={setHistory}
											APIResponse={APIResponse}
											setAPIResponse={setAPIResponse}
											text={selectedLanguage.login_text}
										/>
									}
								/>
								<Route path='/signup' element={<Signup text={selectedLanguage.signup_text} />} />
								<Route
									path='/results'
									element={
										user.emailVerified && (
											<Results
												answers={interiorAnswers}
												setAnswers={setInteriorAnswers}
												history={history}
												setHistory={setHistory}
												APIResponse={APIResponse}
												setAPIResponse={setAPIResponse}
												isActiveUser={isActiveUser}
												isMobile={isMobile}
												numberOfSubmits={parseInt(numberOfSubmits)}
												allowedNumberOfSubmits={allowedNumberOfSubmits}
												client={client}
												interiorResultFormAnswers={interiorResultFormAnswers}
												setInteriorResultFormAnswers={setInteriorResultFormAnswers}
												exteriorResultFormAnswers={exteriorResultFormAnswers}
												setExteriorResultFormAnswers={setExteriorResultFormAnswers}
												constructSelectOptions={constructSelectOptions}
												pageText={selectedLanguage.results_general_text}
												setIsPromptDisplayed={setIsPromptDisplayed}
												isPromptDisplayed={isPromptDisplayed}
												exteriorAnswers={exteriorAnswers}
												setExteriorAnswers={setExteriorAnswers}
												appID={appID}
												dataCleint={dataCleint}
												setDataCleint={setDataCleint}
											/>
										)
									}
								/>
								<Route
									path='/profile'
									element={
										<Profile
											isMobile={isMobile}
											user={user}
											pageText={selectedLanguage.profile_general_text}
											allowedNumberOfSubmits={allowedNumberOfSubmits}
										/>
									}
								/>
								<Route
									path='/applications'
									element={
										user.emailVerified ? (
											<Applications setAppID={setAppID} pageText={selectedLanguage.apps_text} />
										) : (
											<Navigate to='/login' />
										)
									}
								/>
								<Route
									path='/pricing'
									element={
										<Pricing
											isMobile={isMobile}
											itemsList={itemsList}
											setItemsList={setItemsList}
										/>
									}
								/>
								<Route
									path='/contact'
									element={
										<Contact isMobile={isMobile} pageText={selectedLanguage.contact_general_text} />
									}
								/>
								<Route path='/forget-password' element={<ForgetPassword />} />
								<Route path='/reset-password' element={<ResetPassword />} />
								<Route path='/send-otp' element={<SendOTP />} />
								<Route
									path='/email-verification'
									element={<EmailVerification setIsVerified={setIsVerified} />}
								/>
								<Route
									path='/payment-success'
									element={<PaymentSuccess pageText={selectedLanguage.payment_success_text} />}
								/>
								<Route
									path='/payment-cancel'
									element={
										<PaymentCancel
											text={selectedLanguage.payment_cancel_text}
											isMobile={isMobile}
										/>
									}
								/>
							</Routes>
						</Layout>
					</Router>
				</div>
			</div>
		</div>
	);
};

export default App;
