import { useState } from 'react';
import axios from 'axios';
import WhiteLogo from '../../components/Logo/WhiteLogo';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavLink, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import './ResetPassword.scss';

function ResetPassword() {
	const API_URL = process.env.REACT_APP_API_URL;
	const validationSchema = Yup.object().shape({
		otp: Yup.string().required('Otp is required'),
		password: Yup.string()
			.required('Password is required')
			.min(6, 'Password length should be at least 6 characters')
			.max(12, 'Password cannot exceed more than 12 characters'),
		cpassword: Yup.string()
			.required('Confirm Password is required')
			.min(6, 'Password length should be at least 6 characters')
			.max(12, 'Password cannot exceed more than 12 characters')
			.oneOf([Yup.ref('password')], 'Passwords do not match'),
	});
	const navigate = useNavigate();
	const formOptions = { resolver: yupResolver(validationSchema) };
	const { register, handleSubmit, formState, reset } = useForm(formOptions);
	const { errors } = formState;
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState('Changing your password');
	const forgetEmail = sessionStorage.getItem('forget-email');
	console.log('forgetEmail', forgetEmail);
	const onSubmit = (data) => {
		setIsLoading(true);
		axios
			.post(`${API_URL}/api/client/submit-otp`, {
				otp: data.otp,
				password: data.password,
			})
			.then((res) => {
				console.log(res.data);
				if (res.data.code === 200) {
					navigate('/login');
				} else {
					setError('Wrong Otp');
				}
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setIsLoading(false);
				setError(err.message);
			});
	};

	return (
		<div className='login-page'>
			<div className='login-wrapper password'>
				<div className='login-logo'>
					<WhiteLogo />
				</div>
				<div className='details'>
					<h3 className='title'>Reset your password</h3>
					<p className='text'>Check your email inbox for junk mail</p>
					<ol className='steps'>
						<li>Use the OTP number from the email we sent you in the OTP input feild below.</li>
						<li>Type your new password.</li>
						<li>Confirm your new password.</li>
					</ol>
				</div>
				<form className='login-form' onSubmit={handleSubmit(onSubmit)}>
					<div className='form-group'>
						<input type='text' className='form-control' placeholder='Otp' {...register('otp')} />
					</div>
					<span className='errorValidation'>{errors.otp?.message}</span>

					<div className='form-group'>
						<input
							type='password'
							className='form-control'
							id='inputPassword'
							{...register('password')}
							placeholder='Password'
						/>
					</div>
					<span className='errorValidation'>{errors.password?.message}</span>
					<div className='form-group'>
						<input
							type='password'
							className='form-control border-radius'
							id='inputConfirmPassword'
							{...register('cpassword')}
							placeholder='Confirm password'
						/>
					</div>
					<span className='errorValidation'>{errors.cpassword?.message}</span>

					<button type='submit' className='login-btn'>
						Change Password
					</button>

					{error && <span className='errorValidation'>{error}</span>}

					<p className='sign-up-link'>
						Already have an account ? <NavLink to='/login'>Login</NavLink>
					</p>
				</form>
			</div>
			{isLoading && <Loading loadingMessage={loadingMessage} />}
		</div>
	);
}

export default ResetPassword;
