import { useState,useEffect } from 'react'

import { useAuthContext } from './useAuthContext'

export const useGoogleAuth = () => {
    const { dispatch } = useAuthContext();
    const API_URL = process.env.REACT_APP_API_URL;
  
const loginGoogle = async (code) => {
   
    // return   fetch(`${API_URL}/api/client/googlelogin`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ code }),
    // }).then((res) => {
       
    //   if (res.ok) {
       
    //     return res.json();
    //   } else {
    //     return Promise.reject(res);
    //   }
    // });

    const response =await fetch(`${API_URL}/api/client/googlelogin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code })
        })
    const json = await response.json()
      
    
    if (response.ok) {
      // save the user to local storage
      
      localStorage.setItem('client', JSON.stringify(json))
      
      // update the auth context
      dispatch({ type: 'LOGIN', payload: json })
    
    }

  };
  
  return { loginGoogle }

}