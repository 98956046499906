import React, { useState, useEffect } from 'react';
import WhiteLogo from '../../components/Logo/WhiteLogo';
import Loading from '../../components/Loading/Loading';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import * as Yup from 'yup';
import './Login.scss';
import { useLogin } from '../../hooks/useLogin';
import { useAuthContext } from '../../hooks/useAuthContext';
import GoogleLogin from 'react-google-login';
import { useGoogleAuth } from '../../hooks/useGoogleAuth';
import { loadGapiInsideDOM } from 'gapi-script';

const Login = ({ text }) => {
	const { login, errorLogin, isLoading } = useLogin();
	const { loginGoogle } = useGoogleAuth();
	const navigate = useNavigate();

	const validationSchema = Yup.object().shape({
		email: Yup.string().required('Email is required').email('Email is invalid'),
		password: Yup.string().required('Password is required'),
	});
	const formOptions = { resolver: yupResolver(validationSchema) };
	const { register, handleSubmit, formState, reset } = useForm(formOptions);
	const { errors } = formState;
	const [error, setError] = useState('');
	const [loadingMessage, setLoadingMessage] = useState('Logging you in');
	const [errorMessage, setErrorMessage] = useState('');

	const onLogin = async (data) => {
		await login(data.email, data.password);
	};

	const responseGoogle = async (authResult) => {
		try {
			if (authResult.code) {
				const result = await loginGoogle(authResult.code);
				navigate('/');
				//props.loginGoogle(result);
			} else {
				throw new Error(authResult);
			}
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		(async () => {
			await loadGapiInsideDOM();
		})();
	});

	return (
		<div className='login-page'>
			<div className='login-wrapper'>
				<div className='login-logo'>
					<WhiteLogo />
				</div>
				<div className='details'>
					<h3 className='title'>{text.title}</h3>
				</div>
				<form className='login-form' onSubmit={handleSubmit(onLogin)}>
					<div className='form-group'>
						<input
							type='email'
							className='form-control'
							id='inputEmail'
							placeholder='Email Address'
							{...register('email')}
						/>
					</div>
					<span className='errorValidation'>{errors.email?.message}</span>

					<div className='form-group'>
						<input
							type='password'
							className='form-control'
							id='inputPassword'
							{...register('password')}
							placeholder='Password'
						/>
					</div>
					<span className='errorValidation'>{errors.password?.message}</span>
					{error && <span className='errorValidation'>{error}</span>}
					<div className='forgot'>
						<span onClick={() => navigate('/forget-password')}>{text.forgot_password}</span>
					</div>
					{errorLogin && <span className='errorValidation '>{errorLogin}</span>}
					<button type='submit' className='login-btn'>
						{text.login}
					</button>
					<p className='sign-up-link'>
						{text.dnt_have_account} <NavLink to='/signup'>{text.signup}</NavLink>
					</p>
					<div className='center-div-line'>
						<span>{text.login_with}</span>
					</div>
					<div className='social-login'>
						<GoogleLogin
							clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
							buttonText=''
							responseType='code'
							redirectUri='postmessage'
							onSuccess={responseGoogle}
							onFailure={responseGoogle}
							cookiePolicy={'single_host_origin'}>
							<div className='form-btn google-btn'>
								<FontAwesomeIcon icon={faGoogle} />
							</div>
						</GoogleLogin>
					</div>
				</form>
			</div>

			{isLoading && <Loading loadingMessage={loadingMessage} />}
			{!isLoading && errorMessage && (
				<ErrorHandler
					errorMessage={errorMessage}
					navigate={navigate}
					setErrorMessage={setErrorMessage}
				/>
			)}
		</div>
	);
};

export default Login;
