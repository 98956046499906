import { useEffect, useState } from 'react';
import Input from '../../components/Input/Input';
import CheckboxGroup from '../../components/CheckboxGroup/CheckboxGroup';
import Loading from '../../components/Loading/Loading';
import Success from '../../components/Success/Success';
import Failure from '../../components/Failure/Failure';
import './Contact.scss';
import axios from 'axios';

const Contact = ({ pageText }) => {
	const [formName, setFormName] = useState('');
	const [formEmail, setFormEmail] = useState('');
	const [formNumber, setFormNumber] = useState('');
	const [formMessage, setFormMessage] = useState('');
	const [size, setSize] = useState('');
	const [selectedBoxID, setSelectedBoxID] = useState('other');
	const [isSubmitBtnEnabled, setIsSubmitBtnEnabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isFailure, setIsFailure] = useState(false);

	const path = sessionStorage.getItem('path');
	const theatreImageURL = sessionStorage.getItem('theatreImageURL');

	const handleCheckboxChange = (e) => {
		const id = e.target.id;
		const cloned = [...checkboxList];
		cloned.map((item) => {
			if (item.id === id) {
				item.checked = true;
				setSelectedBoxID(id);
			} else {
				item.checked = false;
			}
		});
		setChecboxList(cloned);
	};

	const [checkboxList, setChecboxList] = useState([
		{
			id: 'other',
			name: 'other',
			label: pageText.other,
			onChange: (e) => handleCheckboxChange(e),
			checked: true,
		},
		{
			id: 'purchase',
			name: 'purchase',
			label: pageText.purchase,
			onChange: (e) => handleCheckboxChange(e),
			checked: false,
		},
		{
			id: 'design',
			name: 'design',
			label: pageText.design,
			onChange: (e) => handleCheckboxChange(e),
			checked: false,
		},
	]);

	const handleFormSubmit = (evt) => {
		if (isSubmitBtnEnabled) {
			setIsLoading(true);
			const formData = {
				name: formName,
				email: formEmail,
				mobileNumber: formNumber ? formNumber : 'not available',
				contactFor: selectedBoxID,
				message: formMessage,
				image: theatreImageURL ? theatreImageURL : 'not available',
				size: size ? size : 'not available',
			};
			if (selectedBoxID === 'design') {
				formData.size = size ? size : 'not available';
				formData.image = theatreImageURL ? theatreImageURL : 'not available';
			}
			axios
				.post(`${process.env.REACT_APP_API_URL}/api/contact`, {
					name: formData.name,
					email: formData.email,
					mobileNumber: formData.mobileNumber,
					contactFor: formData.contactFor,
					message: formData.message,
					image: formData.image,
					size: formData.size,
				})
				.then((response) => {
					if (response.status === 200) {
						setIsLoading(false);
						setIsSuccess(true);
						setIsFailure(false);
					} else {
						setIsLoading(false);
						setIsFailure(true);
					}
				})
				.catch((error) => {
					setIsLoading(false);
					setIsFailure(true);
				});
		} else {
			evt.preventDefault();
		}
	};

	const handleFailureLinkClick = () => {
		setIsFailure(false);
	};

	useEffect(() => {
		if (formName && formEmail && formMessage) {
			setIsSubmitBtnEnabled(true);
		} else {
			setIsSubmitBtnEnabled(false);
		}
	}, [formName, formEmail, formMessage]);

	useEffect(() => {
		const cloned = [...checkboxList];
		if ((path === '/results' || path === '/profile') && theatreImageURL) {
			cloned.map((item) => (item.checked = item.id === 'design' ? true : false));
			setSelectedBoxID('design');
		} else if (path === '/pricing') {
			cloned.map((item) => (item.checked = item.id === 'purchase' ? true : false));
			setSelectedBoxID('purchase');
		}
		setChecboxList(cloned);
	}, []);

	useEffect(() => {
		const cloned = [...checkboxList];
		cloned.forEach((item) => {
			item.label = pageText[item.id];
		});
		setChecboxList(cloned);
	}, [pageText]);

	return (
		<div className='contact'>
			<div className='contact-info'>
				<h1>{pageText.title}</h1>
			</div>

			<div className='contact-data'>
				<Input
					id='name-inpt'
					placeholderText={pageText.name}
					type='text'
					onChange={(e) => setFormName(e.target.value)}
					optional=''
				/>
				<Input
					id='email-inpt'
					placeholderText={pageText.email}
					type='email'
					onChange={(e) => setFormEmail(e.target.value)}
					optional=''
				/>
				<Input
					id='number-inpt'
					placeholderText={pageText.phone_number}
					type='number'
					onChange={(e) => setFormNumber(e.target.value)}
					optional={pageText.optional}
				/>

				<CheckboxGroup labelText={pageText.contact_for} checkboxList={checkboxList} />
				{selectedBoxID === 'design' && (
					<div className='design-path'>
						{theatreImageURL && (
							<>
								<p>{pageText.interested_in_design}</p>
								<img src={theatreImageURL} alt='' />
							</>
						)}
						<Input
							id='room-size'
							placeholderText={pageText.room_size}
							type='text'
							onChange={(e) => setSize(e.target.value)}
							optional={pageText.room_size_in_meters}
						/>
					</div>
				)}

				<div className='textarea-wrapper'>
					<textarea
						id='textarea-inpt'
						placeholder={pageText.your_message}
						onChange={(e) => setFormMessage(e.target.value)}
					/>
				</div>
				<div
					className={`submit-btn${isSubmitBtnEnabled ? ' enabled' : ' disabled'}`}
					role='button'
					onClick={(e) => handleFormSubmit(e)}>
					<span>{pageText.submit}</span>
				</div>
			</div>
			{isLoading && <Loading loadingMessage={pageText.sending} from='contact' />}
			{isSuccess && <Success text={pageText.contact_success_text} />}
			{isFailure && (
				<Failure
					text={pageText.contact_cancel_text}
					handleFailureLinkClick={handleFailureLinkClick}
				/>
			)}
		</div>
	);
};

export default Contact;
