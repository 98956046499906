import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuthContext } from './useAuthContext';

export const useLogin = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [errorLogin, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(null);
	const { dispatch } = useAuthContext();
	// const [isEmailVerified, setIsEmailVerified] = useState(false);
	const navigate = useNavigate();
	const { client } = useAuthContext();

	const login = async (email, password) => {
		setIsLoading(true);
		setError(null);

		const response = await fetch(`${API_URL}/api/client/loginClient`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ email, password }),
		});
		const json = await response.json();

		if (!response.ok) {
			setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
			localStorage.setItem('client', JSON.stringify(json));
			dispatch({ type: 'LOGIN', payload: json });
			setIsLoading(false);
		}
	};

	const getDataClient = (id) => {
		axios.get(`${API_URL}/api/client/clients/${id}`).then((res) => {
			if (res.data != 0) {
				const t = res.data.data.emailVerified;
				if (t === true) {
					navigate('/');
				} else {
					navigate('/send-otp');
				}
			}
		});
	};

	useEffect(() => {
		if (client) {
			getDataClient(client.id);
		}
	}, [client]);

	return { login, isLoading, errorLogin };
};
