import React from 'react';
import './Logo.scss';

const WhiteLogo = ({ isMobile, onClick }) => {
	return (
		<div className={`logo${isMobile ? ' mobile-logo' : ''}`} onClick={onClick}>
			<svg
				width={isMobile ? '24' : '32'}
				height={isMobile ? '18' : '26'}
				viewBox='0 0 42 36'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M1 0V34.9815C1 34.9895 1.00882 34.9942 1.01547 34.9899L21.7538 21.4323C21.7572 21.43 21.7617 21.4301 21.765 21.4325L40.9842 34.9889C40.9909 34.9936 41 34.9888 41 34.9807V13.5714'
					stroke='white'
					strokeWidth={isMobile ? '4' : '3'}
				/>
			</svg>
		</div>
	);
};

export default WhiteLogo;
