import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../components/Loading/Loading';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import ImageTheatre from '../../components/ImageTheatre/ImageTheatre';
import Prompt from '../../components/Prompt/Prompt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewGenerationForm from '../../containers/NewGenerationForm/NewGenerationForm';
import { faXmark, faCircleCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { faShareFromSquare } from '@fortawesome/free-regular-svg-icons';
import { useAuthContext } from '../../hooks/useAuthContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DownloadIcon from '../../assets/SVG/DownloadIcon';
import OutOfSubmits from '../../components/OutOfSubmits/OutOfSubmits';
import MailIcon from '../../assets/SVG/MailIcon';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Results.scss';

const Results = ({
	answers,
	setAnswers,
	history,
	setHistory,
	APIResponse,
	setAPIResponse,
	isMobile,
	allowedNumberOfSubmits,
	isActiveUser,
	interiorResultFormAnswers,
	constructSelectOptions,
	pageText,
	isPromptDisplayed,
	setIsPromptDisplayed,
	exteriorAnswers,
	setExteriorAnswers,
	exteriorResultFormAnswers,
	appID,
}) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const siderRef = useRef();
	const navigate = useNavigate();
	const { client } = useAuthContext();
	const [userToken, setUserToken] = useState(null);

	const [imageHistory, setImageHistory] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState(pageText.generating_your_img);
	const [errorMessage, setErrorMessage] = useState('');
	const [isShareMenuDisplayed, setIsShareMenuDisplayed] = useState(false);
	const [isCopySuccessDisplayed, setIsCopySuccessDisplayed] = useState(false);
	const [dataCliendID, setDataCliendID] = useState();
	const [clientID, setClientID] = useState('');
	const [nbrSubmitupdate, setNbrSubmitupdate] = useState('');
	const [clientImages, setClientImages] = useState([]);
	const [isTheatreOpened, setIsTheatreOpened] = useState(false);
	const [theatreImageURL, setTheatreImageURL] = useState('');
	const [isGenerateBtnEnabled, setIsGenerateBtnEnabled] = useState(false);

	let data1;

	const isOutOfTokens = nbrSubmitupdate >= allowedNumberOfSubmits && !isActiveUser;

	const getDataClient = (id) => {
		axios.get(`${API_URL}/api/data/dataClients/client/${id}`).then((res) => {
			if (res.data.dataclient !== 0) {
				setNbrSubmitupdate(parseInt(res.data?.dataclient[0]?.nbSubmit));
				setDataCliendID(res.data.dataclient[0]._id);
				// setIsActiveUser(res.data.dataclient[0].user.active);
				const temp = [];
				res.data.dataclient[0].images.forEach((img) => temp.push(img));
				setClientImages(temp);

				const clonedHistory = [...history];
				if (clonedHistory.length === 0) {
					res.data.dataclient[0].images.map((img) => clonedHistory.push(img));
					setHistory(clonedHistory);
				}
			}
		});
	};

	useEffect(() => {
		if (client === null) {
			setUserToken('');
		} else {
			setUserToken(client.token);
			setClientID(client.id);
			getDataClient(client.id);
		}
	}, [client]);

	const handleShareClick = () => {
		setIsShareMenuDisplayed(!isShareMenuDisplayed);
	};

	const shortenImageURL = () => {
		const selectedImageURL = APIResponse?.length && APIResponse[0];
		if (selectedImageURL?.length > 35) {
			const part1 = selectedImageURL.substring(0, 30);
			const part2 = selectedImageURL.split('/');
			const end = part2[part2.length - 1];
			const part3 = end.substring(end.length - 15);
			return `${part1}.../${part3}`;
		}
		return selectedImageURL;
	};

	const handleImageDownload = () => {
		fetch(APIResponse[0], {
			method: 'GET',
			headers: {},
		})
			.then((response) => {
				response.arrayBuffer().then(function (buffer) {
					const url = window.URL.createObjectURL(new Blob([buffer]));
					const link = document.createElement('a');
					link.href = url;
					// link.download = "walls-kw";
					link.setAttribute('download', APIResponse[0]); //or any other extension
					document.body.appendChild(link);
					link.click();
				});
			})
			.catch((err) => {
				//TODO: FIX ME -- show error box, image can not be downloded at the moment
			});
	};

	const handleCopyLink = () => {
		navigator.clipboard.writeText(APIResponse[0]);
		setIsCopySuccessDisplayed(true);
		setTimeout(() => {
			setIsCopySuccessDisplayed(false);
		}, 2000);
	};

	const handleSelectChange = (e) => {
		const { id, value } = e.target;
		if (appID.includes('interior')) {
			const clonedAnswers = { ...answers };
			const selectID = id.substring(id.indexOf('-'), 0);

			const selectedValue = interiorResultFormAnswers[selectID].options.filter(
				(option) => option.name === value,
			)[0];
			clonedAnswers[selectID] = selectedValue;
			setAnswers(clonedAnswers);
		} else if (appID.includes('exterior')) {
			const clonedAnswers = { ...exteriorAnswers };
			const selectID = id.substring(id.indexOf('-'), 0);

			const selectedValue = exteriorResultFormAnswers[selectID].options.filter(
				(option) => option.name === value,
			)[0];
			clonedAnswers[selectID] = selectedValue;
			setExteriorAnswers(clonedAnswers);
		}
	};

	const handleShowNewResultsClick = () => {
		if (client && nbrSubmitupdate >= allowedNumberOfSubmits && !isActiveUser) {
			navigate('/pricing');
		} else {
			const answersToSend = {};
			if (appID.includes('interior')) {
				for (const key in answers) {
					answersToSend[key] = answers[key].name;
				}
			} else if (appID.includes('exterior')) {
				for (const key in exteriorAnswers) {
					answersToSend[key] = exteriorAnswers[key].name;
				}
			}
			if (userToken) {
				setIsLoading(true);
				axios
					.post(`${API_URL}/generateImage`, { data: answersToSend, appID: appID })
					.then((response) => {
						if (response.status === 200 && response.data.success) {
							const clonedHistory = [...history];
							response?.data?.results.forEach((item) => clonedHistory.push(item));
							setHistory(clonedHistory);
							setAPIResponse(response.data.results);
							const tempNumberOfSubmits = nbrSubmitupdate + 1;
							const temp = [...clientImages];
							temp.push(response?.data?.results[0]);
							data1 = {
								nbSubmit: `${tempNumberOfSubmits}`,
								images: temp,
								user: `${clientID}`,
							};
							axios
								.patch(`${API_URL}/api/data/dataClients/update/${dataCliendID}`, data1)
								.then((res) => {})
								.catch((error) => {});

							setIsLoading(false);
							getDataClient(clientID);
						}
					})
					.catch((error) => {
						setErrorMessage(error.response.data.error);
						setIsLoading(false);
					});
			}
		}
	};

	const handleSideImageClick = (e, images) => {
		e.stopPropagation();
		const sidesChildren = siderRef.current.childNodes;
		if (e.target.classList[0] === 'slide-image') {
			const imageParentID = e.target.parentNode.parentNode.parentNode.id;
			sidesChildren.forEach((node) => {
				if (node.id === imageParentID) {
					node.classList.add('selected');
				} else {
					node.classList.remove('selected');
				}
			});
		} else {
			const imageParentID = e.target.id;
			sidesChildren.forEach((node) => {
				if (node.id === imageParentID) {
					node.classList.add('selected');
				} else {
					node.classList.remove('selected');
				}
			});
		}

		setAPIResponse(images);
	};

	const groupArrayByN = (n, data) => {
		if (data) {
			let result = [];
			for (let i = 0; i < data.length; i += n) result.push(data.slice(i, i + n));
			return result;
		}
	};

	const handleMainImageClick = (url) => {
		setTheatreImageURL(url);
		setIsTheatreOpened(true);
	};

	useEffect(() => {
		constructSelectOptions();
	}, [answers, exteriorAnswers]);

	useEffect(() => {
		const localHistory = [...history];
		const parsed = groupArrayByN(1, localHistory);
		setImageHistory(parsed.reverse());
		if (APIResponse.length === 0) {
			if (parsed && parsed[0]) {
				setAPIResponse(parsed[0]);
			}
		}
	}, [history]);

	useEffect(() => {
		sessionStorage.setItem('path', window.location.pathname);
	}, []);

	const promptRejectAction = () => {
		setIsPromptDisplayed(false);
	};

	const promptAcceptAction = () => {
		setIsPromptDisplayed(false);
		navigate('/applications');
	};

	const handleQuotationRequestClick = () => {
		sessionStorage.setItem('theatreImageURL', APIResponse[0]);
		navigate('/contact');
	};

	return (
		<div className='results'>
			{isOutOfTokens && <OutOfSubmits />}
			{!isOutOfTokens && (
				<>
					{!isMobile && (
						<NewGenerationForm
							interiorResultFormAnswers={interiorResultFormAnswers}
							exteriorResultFormAnswers={exteriorResultFormAnswers}
							appID={appID}
							handleSelectChange={handleSelectChange}
							handleShowNewResultsClick={handleShowNewResultsClick}
							defaultSelectChoiceText={pageText.select_choice}
							generateButtonText={pageText.generate_button_text}
							isGenerateBtnEnabled={isGenerateBtnEnabled}
							setIsGenerateBtnEnabled={setIsGenerateBtnEnabled}
						/>
					)}

					<div className='result-gallery'>
						<div className='images'>
							<div className='contact-section'>
								<div className='invite-text'>
									<h4>{pageText.theatre_text.sub_title}</h4>
									<p>{pageText.invitation}</p>
								</div>
								<div className='navigate' onClick={handleQuotationRequestClick}>
									{/* <MailIcon dimensions={'22'} /> */}
									<span>{pageText.theatre_text.quotation_request} &#x2192;</span>
								</div>
							</div>
							<div className='result-control'>
								<div className='actions'>
									<div className={`share-modal ${isShareMenuDisplayed ? 'displayed' : 'hidden'}`}>
										<div className='share-modal-top'>
											<div className='modal-top-info'>
												<h4>{pageText.share_img_title}</h4>
												<span className='top-info-text'>{pageText.share_img_message}</span>
											</div>
											<FontAwesomeIcon
												className='close-icon'
												icon={faXmark}
												onClick={() => setIsShareMenuDisplayed(false)}
											/>
										</div>
										<hr className='devider' />
										<div className='copy-button' onClick={handleCopyLink}>
											<FontAwesomeIcon className='copy-icon' icon={faCopy} />
											<span className='button-text'>{pageText.copy}</span>
										</div>
										<div className='link'>
											<span className='image-link'>{shortenImageURL()}</span>
										</div>
									</div>
									<div className={`copy-success ${isCopySuccessDisplayed ? 'success' : 'hidden'}`}>
										<FontAwesomeIcon className='success-icon' icon={faCircleCheck} />
										<span className='success-text'>{pageText.link_copied}</span>
									</div>
								</div>
							</div>
							<div className='image-container'>
								{APIResponse.map((image, index) => (
									<div
										key={`main-${index}`}
										className='image-wrapper'
										onClick={() => handleMainImageClick(image)}>
										<LazyLoadImage className='result-image' src={image} alt='image' effect='blur' />
									</div>
								))}

								{APIResponse.length > 0 && APIResponse[0] && (
									<div className='image-icons'>
										<div className='icon share-icon' onClick={handleShareClick}>
											<FontAwesomeIcon icon={faShareFromSquare} />
										</div>
										<div className='icon download-icon' onClick={handleImageDownload}>
											<DownloadIcon dimensions={isMobile ? '14' : '16'} />
										</div>
									</div>
								)}
							</div>
							{imageHistory.length > 0 && (
								<div className='history-gallery'>
									<div className='slider' ref={siderRef}>
										{imageHistory.map((images, index) => (
											<div
												key={`side-${index}`}
												id={`side-${index}`}
												className='slide'
												onClick={(e) => handleSideImageClick(e, images)}>
												{images.map((image, i) => (
													<div key={i}>
														<LazyLoadImage
															className='slide-image'
															src={image}
															alt='image'
															effect='blur'
														/>
													</div>
												))}
											</div>
										))}
									</div>
								</div>
							)}
						</div>
					</div>

					{isMobile && (
						<NewGenerationForm
							interiorResultFormAnswers={interiorResultFormAnswers}
							exteriorResultFormAnswers={exteriorResultFormAnswers}
							appID={appID}
							handleSelectChange={handleSelectChange}
							handleShowNewResultsClick={handleShowNewResultsClick}
							defaultSelectChoiceText={pageText.select_choice}
							generateButtonText={pageText.generate_button_text}
							isGenerateBtnEnabled={isGenerateBtnEnabled}
							setIsGenerateBtnEnabled={setIsGenerateBtnEnabled}
						/>
					)}

					{isLoading && <Loading loadingMessage={loadingMessage} from='results' />}
					{!isLoading && errorMessage && (
						<ErrorHandler
							errorMessage={errorMessage}
							navigate={navigate}
							setErrorMessage={setErrorMessage}
						/>
					)}

					<ImageTheatre
						theatreImageURL={theatreImageURL}
						isTheatreOpened={isTheatreOpened}
						setIsTheatreOpened={setIsTheatreOpened}
						isMobile={isMobile}
						pageText={pageText.theatre_text}
					/>

					{isPromptDisplayed && (
						<Prompt
							setIsPromptDisplayed={setIsPromptDisplayed}
							promptRejectAction={promptRejectAction}
							promptAcceptAction={promptAcceptAction}
							prompText={pageText.promp_text}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default Results;
