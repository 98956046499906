import React, { useEffect } from 'react';
import Question from '../../containers/Question/Question';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './Carousel.scss';

const Carousel = ({
	questions,
	setQuestions,
	activeIndex,
	setActiveIndex,
	answers,
	setAnswers,
	IsNextButtonEnabled,
	isModalDisplayed,
	setIsModalDisplayed,
	handleGenerateImages,
	isMobile,
	handleAnswers,
	buttonRef,
	isOutOfTokens,
	appID,
	pageText,
}) => {
	const handleNext = () => {
		if (IsNextButtonEnabled) {
			const isLastSlide = activeIndex === questions.length - 1;
			const newIndex = isLastSlide ? 0 : activeIndex + 1;
			setActiveIndex(newIndex);
		}
	};

	const handlePrev = () => {
		if (activeIndex !== 0) {
			const isFirstQuestion = activeIndex === 0;
			const newIndex = isFirstQuestion ? questions.length - 1 : activeIndex - 1;
			setActiveIndex(newIndex);
		}
	};

	useEffect(() => {
		const cloned = [...questions];
		if (appID === 'comm_exterior') {
			if (answers.buildingType.id === '') {
				return;
			} else if (answers.buildingType.id !== 'cbt_industrial') {
				cloned[1].choices.map((choice) =>
					(choice.disabled =
						choice.name === '1to2' || choice.name === '2to3' || choice.name === '3to4')
						? true
						: false,
				);
				if (answers.floorsNumber.disabled) {
					const clonedAnswers = { ...answers };
					clonedAnswers.floorsNumber = { id: '', text: '' };
					setAnswers(clonedAnswers);
				}
			} else {
				cloned[1].choices.map((choice) => (choice.disabled = false));
			}
			setQuestions(cloned);
		} else if (appID === 'exterior') {
			if (answers.buildingType.id === '') {
				return;
			} else if (
				answers.buildingType.id === 'bt_apartments_building' ||
				answers.buildingType.id === 'bt_highrise_residential_building' ||
				answers.buildingType.id === 'bt_hotel'
			) {
				cloned[1].choices.map((choice) =>
					(choice.disabled =
						choice.name === '1to2' || choice.name === '2to3' || choice.name === '3to4')
						? true
						: false,
				);
				if (answers.floorsNumber.disabled) {
					const clonedAnswers = { ...answers };
					clonedAnswers.floorsNumber = { id: '', text: '' };
					setAnswers(clonedAnswers);
				}
			} else {
				cloned[1].choices.map((choice) => (choice.disabled = false));
			}
			setQuestions(cloned);
		}
	}, [appID, answers?.buildingType?.id]);

	return (
		<div className='carousel'>
			{!isMobile && !isOutOfTokens && (
				<div ref={buttonRef}>
					{activeIndex < 7 && (
						<div
							className={`carousel-button next${!IsNextButtonEnabled ? ' disabled' : ''}`}
							onClick={handleNext}>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					)}
					{activeIndex > 0 && (
						<div className='carousel-button prev' onClick={handlePrev}>
							<FontAwesomeIcon icon={faChevronLeft} />
						</div>
					)}
				</div>
			)}

			<Question
				id={questions[activeIndex].id}
				title={questions[activeIndex].title}
				text={questions[activeIndex].text}
				choices={questions[activeIndex].choices}
				handleAnswers={handleAnswers}
				activeIndex={activeIndex}
				selectedChoice={answers[questions[activeIndex].id]}
				isModalDisplayed={isModalDisplayed}
				setIsModalDisplayed={setIsModalDisplayed}
				IsNextButtonEnabled={IsNextButtonEnabled}
				handleGenerateImages={handleGenerateImages}
				isOutOfTokens={isOutOfTokens}
				appID={appID}
				pageText={pageText}
			/>
			{isMobile && !isOutOfTokens && (
				<div className='mobile-buttons-wrapper' ref={buttonRef}>
					{activeIndex >= 0 && (
						<div
							className={`carousel-button prev${activeIndex === 0 ? ' invisible' : ''}`}
							onClick={handlePrev}>
							<FontAwesomeIcon icon={faChevronLeft} />
						</div>
					)}
					{activeIndex < 7 && (
						<div
							className={`carousel-button next${!IsNextButtonEnabled ? ' disabled' : ''}`}
							onClick={handleNext}>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default Carousel;
