import './OutOfSubmits.scss';
import { useNavigate } from 'react-router-dom';
import PriceIcon from '../../assets/SVG/PriceIcon';

const OutOfSubmits = () => {
	const navigate = useNavigate();
	return (
		<div className='redirect'>
			<div className='redirect-section'>
				<h1>You are out of submit tokens</h1>
				<p>Buy more tokens at the pricing page</p>
				<div className='navigate' onClick={() => navigate('/pricing')}>
					<PriceIcon dimensions={'22'} />
					<span>Pricing</span>
				</div>
			</div>
		</div>
	);
};

export default OutOfSubmits;
