import React, { useState, useEffect, useRef } from 'react';
import Modal from '../../components/Modal/Modal';
import Carousel from '../../containers/Carousel/Carousel';
import Navigation from '../../components/Navigation/Navigation';
import Loading from '../../components/Loading/Loading';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import { useNavigate } from 'react-router-dom';
import { exteriorDesignStyles } from '../../resources/questionsData';
import axios from 'axios';
import '../InteiorDesignApp/InteiorDesignApp.scss';
import { useAuthContext } from '../../hooks/useAuthContext';
import OutOfSubmits from '../../components/OutOfSubmits/OutOfSubmits';

const ExteriorDesignApp = ({
	exteriorQuestionsData,
	setExteriorQuestionsData,
	answers,
	setAnswers,
	history,
	setHistory,
	setAPIResponse,
	isMobile,
	allowedNumberOfSubmits,
	isActiveUser,
	constructSelectOptions,
	questionsNavList,
	appID,
	pageText,
}) => {
	const buttonRef = useRef(null);
	const API_URL = process.env.REACT_APP_API_URL;
	const navigate = useNavigate();
	const { client } = useAuthContext();
	const [userToken, setUserToken] = useState(null);
	const [dataCliendID, setDataCliendID] = useState();
	const [nbSubmitByID, setNbSubmitByID] = useState(null);
	const [clientID, setClientID] = useState('');
	const [nbrSubmitupdate, setNbrSubmitupdate] = useState('');
	const [clientImages, setClientImages] = useState([]);
	let nbSubmit = 0;
	let data1;

	const getDataClient = (id) => {
		axios.get(`${API_URL}/api/data/dataClients/client/${id}`).then((res) => {
			if (res.data.dataclient != 0) {
				setNbSubmitByID(res.data.dataclient[0].nbSubmit);
				setNbrSubmitupdate(parseInt(res.data.dataclient[0].nbSubmit));
				setDataCliendID(res.data.dataclient[0]._id);
				const temp = [...clientImages];
				res.data.dataclient[0].images.map((img) => {
					temp.push(img);
				});
				setClientImages(temp);
			}
		});
	};

	useEffect(() => {
		if (client === null) {
			setUserToken('');
		} else {
			setUserToken(client.token);
			setClientID(client.id);
			getDataClient(client.id);
		}
	}, [client]);

	const [activeIndex, setActiveIndex] = useState(0);
	const [isModalDisplayed, setIsModalDisplayed] = useState(false);
	const [IsNextButtonEnabled, setIsNextButtonEnabled] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState('Generating your images...');
	const [errorMessage, setErrorMessage] = useState('');

	const handleGenerateImages = async () => {
		const answersToSend = {};
		for (const key in answers) {
			answersToSend[key] = answers[key].name.toLowerCase();
		}
		// answersToSend.appID = appID;
		if (userToken) {
			setIsLoading(true);
			axios
				.post(`${API_URL}/generateImage`, { data: answersToSend, appID: appID })
				.then((response) => {
					if (response.status === 200 && response.data.success) {
						if (response?.data?.results) {
							const clonedHistory = [...history];
							response?.data?.results.forEach((item) => clonedHistory.push(item));
							setHistory(clonedHistory);
							setAPIResponse(response?.data?.results);
							if (nbSubmitByID === 0 || nbSubmitByID === null) {
								nbSubmit++;
								data1 = {
									nbSubmit: `${nbSubmit}`,
									images: response?.data?.results[0],
									user: `${clientID}`,
								};
								axios
									.post(`${API_URL}/api/data/dataClients`, data1)
									.then((res) => {})
									.catch((error) => {});
							} else {
								const tempNumberOfSubmits = nbrSubmitupdate + 1;
								const temp = [...clientImages];
								temp.push(response?.data?.results[0]);
								data1 = { nbSubmit: `${tempNumberOfSubmits}`, images: temp, user: `${clientID}` };
								axios
									.patch(`${API_URL}/api/data/dataClients/update/${dataCliendID}`, data1)
									.then((res) => {})
									.catch((error) => {});
							}
							setIsLoading(false);
							navigate('/results');
						} else {
							setIsLoading(false);
							setErrorMessage('Whops!!. Something went wrong.');
						}
					}
				})
				.catch((error) => {
					setErrorMessage(error?.response?.data?.error);
					setIsLoading(false);
				});
		} else {
			navigate('/login');
		}
	};

	const handleAnswers = (choice) => {
		const clonedAnswers = { ...answers };
		if (activeIndex === 0) {
			clonedAnswers.buildingType = choice;
		} else if (activeIndex === 1) {
			clonedAnswers.floorsNumber = choice;
		} else if (activeIndex === 2) {
			clonedAnswers.archStyle = choice;
		} else if (activeIndex === 3) {
			clonedAnswers.color = choice;
		} else if (activeIndex === 4) {
			clonedAnswers.primaryMaterial = choice;
		} else if (activeIndex === 5) {
			clonedAnswers.secondaryMaterial = choice;
		} else if (activeIndex === 6) {
			clonedAnswers.surroundings = choice;
		} else if (activeIndex === 7) {
			clonedAnswers.landscape = choice;
		} else return;

		setTimeout(() => {
			if (buttonRef.current) {
				buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
			}
		}, 400);
		setAnswers(clonedAnswers);
	};

	useEffect(() => {
		if (activeIndex === 0 && answers.buildingType.id) {
			setIsNextButtonEnabled(true);
		} else if (activeIndex === 1 && answers.floorsNumber.id) {
			setIsNextButtonEnabled(true);
		} else if (activeIndex === 2 && answers.archStyle.id) {
			setIsNextButtonEnabled(true);
		} else if (activeIndex === 3 && answers.color.id) {
			setIsNextButtonEnabled(true);
		} else if (activeIndex === 4 && answers.primaryMaterial.id) {
			setIsNextButtonEnabled(true);
		} else if (activeIndex === 5 && answers.secondaryMaterial.id) {
			setIsNextButtonEnabled(true);
		} else if (activeIndex === 6 && answers.surroundings.id) {
			setIsNextButtonEnabled(true);
		} else if (activeIndex === 7 && answers.landscape.id) {
			setIsNextButtonEnabled(true);
		} else {
			setIsNextButtonEnabled(false);
		}
		constructSelectOptions();
	}, [activeIndex, answers]);

	const isOutOfTokens = nbrSubmitupdate >= allowedNumberOfSubmits && !isActiveUser;

	return (
		<div className='exterior-design-app'>
			{isOutOfTokens && <OutOfSubmits />}
			{!isOutOfTokens && (
				<>
					<Navigation activeIndex={activeIndex} questionsNavList={questionsNavList} />
					<Carousel
						activeIndex={activeIndex}
						appID={appID}
						setActiveIndex={setActiveIndex}
						questions={exteriorQuestionsData}
						setQuestions={setExteriorQuestionsData}
						answers={answers}
						setAnswers={setAnswers}
						IsNextButtonEnabled={IsNextButtonEnabled}
						isModalDisplayed={isModalDisplayed}
						setIsModalDisplayed={setIsModalDisplayed}
						handleGenerateImages={handleGenerateImages}
						isMobile={isMobile}
						handleAnswers={handleAnswers}
						buttonRef={buttonRef}
						isOutOfTokens={isOutOfTokens}
						pageText={pageText}
					/>

					{activeIndex === 2 && (
						<Modal
							isModalDisplayed={isModalDisplayed}
							setIsModalDisplayed={setIsModalDisplayed}
							title={pageText.style_example}
							modalFor='design'
							elemets={exteriorDesignStyles}
							handleSelectedColourChoice={false}
						/>
					)}
					{isLoading && <Loading loadingMessage={loadingMessage} />}
					{!isLoading && errorMessage && (
						<ErrorHandler
							errorMessage={errorMessage}
							navigate={navigate}
							setErrorMessage={setErrorMessage}
							isMobile={isMobile}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default ExteriorDesignApp;
