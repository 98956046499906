const BurgerMenu = ({}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke='#000'
			strokeWidth='2.5'
			strokeLinecap='round'
			strokeLinejoin='round'>
			<line x1='3' y1='12' x2='21' y2='12'></line>
			<line x1='3' y1='6' x2='21' y2='6'></line>
			<line x1='3' y1='18' x2='21' y2='18'></line>
		</svg>
	);
};

export default BurgerMenu;
