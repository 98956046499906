import React from 'react';
import './Loading.scss';

const Loading = ({ loadingMessage }) => {
   return (
      <div className="loading">
         <span className="loader"></span>
         <h4>{loadingMessage}</h4>
      </div>
   );
}

export default Loading;