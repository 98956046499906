import './button.scss';

const Button = ({
	text,
	onClick,
	IsNextButtonEnabled,
	nbrSubmitupdate,
	allowedNumberOfSubmits,
	isActiveUser,
	isOutOfTokens,
}) => {
	const isButtonDisabled = () => {
		if (isOutOfTokens) {
			return true;
		} else {
			return false;
		}
	};

	const handleButtonClasses = () => {
		let classes = 'button-wrapper';
		if (isButtonDisabled()) {
			classes += ' disabled';
		} else if (!IsNextButtonEnabled) {
			classes += ' disabled';
		}
		return classes;
	};

	return (
		<button className={handleButtonClasses()} onClick={onClick} disabled={isButtonDisabled()}>
			{text}
		</button>
	);
};

export default Button;
