import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Landing.scss';

const LandingPage = ({
	numberOfSubmits,
	allowedNumberOfSubmits,
	client,
	isActiveUser,
	pageText,
	isMobile,
}) => {
	const navigate = useNavigate();

	const handleTakeMeToAppClick = () => {
		if (client && numberOfSubmits >= allowedNumberOfSubmits && !isActiveUser) {
			navigate('/pricing');
		} else {
			navigate('/applications');
		}
	};

	return (
		<div className='landing'>
			<div className='landing-container'>
				<div className='landing-wrapper'>
					<h1 className='title'>
						Walls Design <span className='gradient'>AI</span>
					</h1>
					<p className='text'>{pageText.title_text}</p>
					<div className='landing-button-wrapper'>
						<button className='landing-button' onClick={handleTakeMeToAppClick}>
							<span>{pageText.take_me_button_text}</span>
						</button>
					</div>
				</div>
				{/* {isMobile && <div className='landing-footer'></div>} */}
			</div>
		</div>
	);
};

export default LandingPage;
