import React from 'react';
import './Choice.scss';

function Choice({ selected, handleAnswers, choice, disabled }) {
	return (
		<div
			className={`choice${selected ? ' selected' : ''}${disabled ? ' disabled' : ''}`}
			role='button'
			onClick={() => handleAnswers(choice)}>
			<div className='choice-wrapper'>
				<span>{choice.text}</span>
			</div>
		</div>
	);
}

export default Choice;
