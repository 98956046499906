import { useState } from 'react';
import axios from 'axios';
import '../ResetPassword/ResetPassword.scss';

import WhiteLogo from '../../components/Logo/WhiteLogo';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
function ForgetPassword() {
	const API_URL = process.env.REACT_APP_API_URL;
	const navigate = useNavigate();
	const [error, setError] = useState('');
	const validationSchema = Yup.object().shape({
		email: Yup.string().required('Email is required').email('Email is invalid'),
	});

	const formOptions = { resolver: yupResolver(validationSchema) };
	const { register, handleSubmit, formState, reset } = useForm(formOptions);
	const { errors } = formState;
	const [isLoading, setIsLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState('Sending password');

	const onSubmit = (data) => {
		sessionStorage.setItem('forget-email', data.email);
		setIsLoading(true);
		axios
			.post(`${API_URL}/api/client/send-otp`, {
				email: data.email,
			})
			.then((res) => {
				console.log(res.data);
				if (res.data.code === 200) {
					navigate('/reset-password');
				} else {
					setError('Server Error');
				}
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setIsLoading(false);
				setError(err.message);
			});
	};

	return (
		<div className='login-page'>
			<div className='login-wrapper password'>
				<div className='login-logo'>
					<WhiteLogo />
				</div>
				<div className='details'>
					<h3 className='title'>Forget password</h3>
					<p className='text'>We’ll send you an email to reset your password.</p>
				</div>
				<form className='login-form' onSubmit={handleSubmit(onSubmit)}>
					<div className='form-group'>
						<input
							type='email'
							className='form-control'
							placeholder='Email Address'
							{...register('email')}
						/>
					</div>
					<span className='errorValidation'>{errors.email?.message}</span>

					<button type='submit' className='login-btn'>
						Send Password
					</button>

					{error && <span className='errorValidation'>{error}</span>}

					<p className='sign-up-link'>
						Already have an account ? <NavLink to='/login'>Login</NavLink>
					</p>
				</form>
			</div>
			{isLoading && <Loading loadingMessage={loadingMessage} />}
		</div>
	);
}

export default ForgetPassword;
