import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './Modal.scss';

const Modal = ({
	modalFor,
	title,
	text,
	elemets,
	isModalDisplayed,
	setIsModalDisplayed,
	handleSelectedColourChoice,
	selectedColour,
	modalTitle,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [selectedDesignStyle, setSelectedDesignStyle] = useState('');

	const handleDesignStyleImageClick = (img) => {
		setSelectedDesignStyle(img);
		setIsExpanded(true);
	};

	const handleCloseModal = () => {
		setIsModalDisplayed(false);
		setIsExpanded(false);
	};

	return (
		<div
			className={`modal${isModalDisplayed ? ' displayed' : ' hidden'}${
				modalFor === 'design' ? ' design' : ''
			}${isExpanded ? ' expanded' : ''}`}>
			<div className='modal-wrapper'>
				<div className='top'>
					<div className='close-icon' onClick={handleCloseModal}>
						<FontAwesomeIcon className='icon' icon={faXmark} />
					</div>
					<div className='information'>
						<p>{modalTitle ? modalTitle : title}</p>
						{text && <p>{text}</p>}
					</div>
				</div>
				<div className='content'>
					{modalFor === 'design' && (
						<div className='designs-wrapper'>
							{elemets.map((elm, index) => (
								<div
									className='design-style'
									key={index}
									style={{ backgroundImage: `url(${elm})` }}
									onClick={() => handleDesignStyleImageClick(elm)}></div>
							))}
						</div>
					)}
					{isExpanded && (
						<div className='modal-extras'>
							<div
								className='selected-design-style'
								style={{ backgroundImage: `url(${selectedDesignStyle})` }}></div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Modal;
