import React from 'react';
import Card from '../../components/Card/Card';
import './Applications.scss';

const Applications = ({ setAppID, pageText }) => {
	const apps = [
		{
			id: 'interior',
			title: 'Residential Interior Design AI',
			text: pageText.interior_ai,
			path: '/applications/interior-design',
		},
		{
			id: 'comm_interior',
			title: 'Commercial Interior Design AI',
			text: pageText.comm_interior_ai,
			path: '/applications/interior-design',
		},
		{
			id: 'exterior',
			title: 'Residential Exterior Design AI',
			text: pageText.exterior_ai,
			path: '/applications/exterior-design',
		},
		{
			id: 'comm_exterior',
			title: 'Commercial Exterior Design AI',
			text: pageText.comm_exterior_ai,
			path: '/applications/exterior-design',
		},
	];

	return (
		<div className='applications'>
			<div className='app-title'>
				<h1>{pageText.title}</h1>
				<p>{pageText.sub_title}</p>
			</div>
			<div className='app-cards'>
				{apps.map((app) => (
					<Card key={app.id} info={app} setAppID={setAppID} />
				))}
			</div>
		</div>
	);
};

export default Applications;
