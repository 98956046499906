import { NavLink } from 'react-router-dom';
import HomeIcon from '../../assets/SVG/HomeIcon';
import CloseIcon from '../../assets/SVG/CloseIcon';
import AppsIcon from '../../assets/SVG/AppsICon';
import ResultsIcon from '../../assets/SVG/ResultsIcon';
import UserIcon from '../../assets/SVG/UserIcon';
import LogoutIcon from '../../assets/SVG/LogoutIcon';
import LoginIcon from '../../assets/SVG/LoginIcon';
import PriceIcon from '../../assets/SVG/PriceIcon';
import MaileIcon from '../../assets/SVG/MailIcon';
import './Menu.scss';

const Menu = ({ setIsMenuDisplayed, user, logout, isMenuDisplayed, dropDownRef, menuItems }) => {
	const handleLogout = () => {
		logout();
		setIsMenuDisplayed(false);
	};

	return (
		<div className={`menu ${isMenuDisplayed ? 'displayed' : 'hidden'}`} ref={dropDownRef}>
			<div className='menu-top'>
				<div className='close-menu' onClick={() => setIsMenuDisplayed(false)}>
					<CloseIcon />
				</div>
			</div>
			<hr></hr>

			<div className='menu-content'>
				<div className='menu-item'>
					<HomeIcon />
					<NavLink to='/' onClick={() => setIsMenuDisplayed(false)}>
						<span>{menuItems.home}</span>
					</NavLink>
				</div>
				<div className='menu-item'>
					<AppsIcon />
					<NavLink to='/applications' onClick={() => setIsMenuDisplayed(false)}>
						<span>{menuItems.applications}</span>
					</NavLink>
				</div>
				{/* <div className='menu-item'>
					<PriceIcon />
					<NavLink to='/pricing' onClick={() => setIsMenuDisplayed(false)}>
						<span>{menuItems.price}</span>
					</NavLink>
				</div> */}
				<div className='menu-item'>
					<MaileIcon />
					<NavLink to='/contact' onClick={() => setIsMenuDisplayed(false)}>
						<span>{menuItems.contact}</span>
					</NavLink>
				</div>
				{user.emailVerified ? (
					<div className='profile-items-wrapper'>
						<div className='profile-items'>
							<ResultsIcon />
							<NavLink to='/results' onClick={() => setIsMenuDisplayed(false)}>
								<span>{menuItems.results}</span>
							</NavLink>
						</div>
						<div className='profile-items'>
							<UserIcon />
							<NavLink to='/profile' onClick={() => setIsMenuDisplayed(false)}>
								<span>{menuItems.profile}</span>
							</NavLink>
						</div>
						<div className='profile-items' onClick={handleLogout}>
							<LogoutIcon />
							<span>{menuItems.logout}</span>
						</div>
					</div>
				) : (
					<div className='profile-items'>
						<LoginIcon />
						<NavLink to='/login' onClick={() => setIsMenuDisplayed(false)}>
							<span>{menuItems.login}</span>
						</NavLink>
					</div>
				)}
			</div>
		</div>
	);
};

export default Menu;
