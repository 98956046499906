import './CheckboxGroup.scss';
const CheckboxGroup = ({ labelText, checkboxList }) => {
	return (
		<div className='checkbox-group'>
			<span>{labelText}</span>
			<div className='checkbox-list'>
				{checkboxList.map((item) => (
					<div key={item.id} className='checkbox-wrapper'>
						<input
							type='checkbox'
							id={item.id}
							name={item.id}
							value={item.label}
							onChange={item.onChange}
							checked={item.checked}
						/>
						<label htmlFor={item.id}>{item.label}</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default CheckboxGroup;
