import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import WhiteLogo from '../../components/Logo/WhiteLogo';
import * as Yup from 'yup';
import { useSignup } from '../../hooks/useSignup';

const Signup = ({ text }) => {
	const { signup, errorLogin } = useSignup();

	const validationSchema = Yup.object().shape({
		firstName: Yup.string().required('First Name is required'),
		lastName: Yup.string().required('Last Name is required'),
		email: Yup.string().required('Email is required').email('Email is invalid'),
		numberMobile: Yup.string().notRequired(),
		password: Yup.string()
			.required('Password is required')
			.min(6, 'Password length should be at least 6 characters')
			.max(12, 'Password cannot exceed more than 12 characters'),
		cpassword: Yup.string()
			.required('Confirm Password is required')
			.min(6, 'Password length should be at least 6 characters')
			.max(12, 'Password cannot exceed more than 12 characters')
			.oneOf([Yup.ref('password')], 'Passwords do not match'),
	});

	const formOptions = { resolver: yupResolver(validationSchema) };
	const { register, handleSubmit, formState, reset } = useForm(formOptions);
	const { errors } = formState;
	const [error, setError] = useState('');
	const [active, setActive] = useState(false);
	const onSubmit = async (data) => {
		await signup(
			data.firstName,
			data.lastName,
			data.email,
			data.password,
			data.numberMobile,
			active,
		);
	};

	return (
		<div className='login-page'>
			<div className='login-wrapper'>
				<div className='login-logo'>
					<WhiteLogo />
				</div>
				<div className='details'>
					<h3 className='title'>{text.title}</h3>
				</div>
				<form className='login-form' onSubmit={handleSubmit(onSubmit)}>
					<div className='form-group'>
						<input
							type='text'
							className='form-control'
							placeholder='First Name '
							{...register('firstName')}
						/>
					</div>
					<span className='errorValidation'>{errors.firstName?.message}</span>
					<div className='form-group'>
						<input
							type='text'
							className='form-control'
							placeholder='Last Name '
							{...register('lastName')}
						/>
					</div>
					<span className='errorValidation'>{errors.lastName?.message}</span>
					<div className='form-group'>
						<input
							type='email'
							className='form-control'
							placeholder='Email Address'
							{...register('email')}
						/>
					</div>
					<span className='errorValidation'>{errors.email?.message}</span>

					<div className='form-group'>
						<input
							type='password'
							className='form-control'
							id='inputPassword'
							// value={password}
							// onChange={(e) => setPassword(e.target.value)}
							{...register('password')}
							placeholder='Password'
						/>
					</div>
					<span className='errorValidation'>{errors.password?.message}</span>
					{error && <span className='errorValidation'>{error}</span>}
					<div className='form-group'>
						<input
							type='password'
							className='form-control border-radius'
							id='inputConfirmPassword'
							// value={confirmPassword}
							// onChange={e => setConfirmPassword(e.target.value)}
							{...register('cpassword')}
							placeholder='Confirm password'
						/>
					</div>
					<span className='errorValidation'>{errors.cpassword?.message}</span>
					<div className='form-group'></div>
					<span className='errorValidation'>{errors.numberMobile?.message}</span>
					{error && <span className='errorValidation'>{error}</span>}
					{errorLogin && <span className='errorValidation '>{errorLogin}</span>}
					<button type='submit' className='login-btn'>
						{text.signup}
					</button>
					<p className='sign-up-link'>
						{text.alrdy_have_account} <NavLink to='/login'>{text.login}</NavLink>
					</p>
				</form>
			</div>
		</div>
	);
};

export default Signup;
