import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Card.scss';

const Card = ({ info, setAppID }) => {
	const navigate = useNavigate();
	return (
		<div
			className='card'
			onClick={() => {
				setAppID(info.id);
				sessionStorage.setItem('appID', info.id);
				navigate(info.path);
			}}>
			<div className='card-wrapper'>
				<h3 className='title'>{info.title}</h3>
				<p className='text'>{info.text}</p>
			</div>
		</div>
	);
};

export default Card;
