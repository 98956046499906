import React from 'react';
import Choice from '../../components/Choice/Choice';
import Button from '../../components/Button/Button';
import './question.scss';

const Question = ({
	title,
	text,
	choices,
	handleAnswers,
	activeIndex,
	selectedChoice,
	isModalDisplayed,
	setIsModalDisplayed,
	IsNextButtonEnabled,
	handleGenerateImages,
	isOutOfTokens,
	appID,
	pageText,
}) => {
	const handleGeneration = (e) => {
		if (isOutOfTokens) {
			e.preventDefault();
		} else if (IsNextButtonEnabled) {
			handleGenerateImages();
		}
	};
	return (
		<div className='question'>
			<div className='info'>
				<div className='info-wrapper'>
					<h1>{title}</h1>
					<p>{text}</p>
				</div>
			</div>
			<div className='choices-wrappepr'>
				<div className='choices'>
					{choices.map((choice, index) => (
						<Choice
							key={index}
							id={choice.id}
							choice={choice}
							selected={choice.id === selectedChoice?.id ? true : false}
							handleAnswers={handleAnswers}
							disabled={choice?.disabled}
						/>
					))}
				</div>
				{activeIndex === 7 && !isOutOfTokens && (
					<Button
						IsNextButtonEnabled={IsNextButtonEnabled}
						text='Generate Image'
						onClick={handleGeneration}
						isOutOfTokens={isOutOfTokens}
						// nbrSubmitupdate={nbrSubmitupdate}
						// allowedNumberOfSubmits={allowedNumberOfSubmits}
						// isActiveUser={isActiveUser}
					/>
				)}

				{appID.includes('interior') && activeIndex === 1 && (
					<div className='modal-label' onClick={() => setIsModalDisplayed(!isModalDisplayed)}>
						<span>{pageText.style_example}</span>
					</div>
				)}
				{appID.includes('exterior') && activeIndex === 2 && (
					<div className='modal-label' onClick={() => setIsModalDisplayed(!isModalDisplayed)}>
						<span>{pageText.style_example}</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default Question;
