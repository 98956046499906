import React, { useState, useEffect, useRef } from 'react';
import WhiteLogo from '../../components/Logo/WhiteLogo';
// import Logo from '../../components/Logo/Logo';
// import { useAuthContext } from '../../hooks/useAuthContext';
import { useNavigate } from 'react-router-dom';
import { useLogout } from '../../hooks/useLogout';
import BurgerMenu from '../../assets/SVG/BurgerMenu';
import Menu from '../../components/Menu/Menu';
import './topbar.scss';

const TopBar = ({ isMobile, language, menuItems, handleLanguageChange, user }) => {
	const { logout } = useLogout();

	const [isMenuDisplayed, setIsMenuDisplayed] = useState(false);
	const dropDownRef = useRef(null);
	const menuRef = useRef(null);
	const navigate = useNavigate();

	useOnClickOutside(dropDownRef, menuRef, () => setIsMenuDisplayed(false));

	const getUserProfileInitials = () => {
		let initals = '';
		if (user !== {}) {
			if (user.firstName && user.lastName) {
				initals = `${user.firstName.substring(0, 1).toUpperCase()}${user.lastName
					.substring(0, 1)
					.toUpperCase()}`;
			} else if (user.firstName && !user.lastName) {
				initals = `${user.firstName.substring(0, 1).toUpperCase()}`;
			} else if (user.email && !user.firstName && !user.lastName) {
				initals = `${user.email.substring(0, 1).toUpperCase()}`;
			}
		}
		return initals;
	};

	return (
		<div className={`top-bar ${window.location.pathname === '/results' ? 's-margin' : ''}`}>
			<WhiteLogo isMobile={isMobile} onClick={() => navigate('/')} />
			<div className='top-bar-right'>
				<div className='language'>
					<span
						id='lang-en'
						className={`lang ${language === 'EN' ? 'selected' : ''}`}
						onClick={handleLanguageChange}>
						EN
					</span>
					<div className='devider'></div>
					<span
						id='lang-ar'
						className={`lang ${language === 'AR' ? 'selected' : ''}`}
						onClick={handleLanguageChange}>
						AR
					</span>
				</div>
				{getUserProfileInitials() && (
					<div
						className='user-profile'
						onClick={() => setIsMenuDisplayed(!isMenuDisplayed)}
						ref={menuRef}>
						{getUserProfileInitials()}
					</div>
				)}
				{!getUserProfileInitials() && (
					<div
						className='menu-wrapper'
						onClick={() => setIsMenuDisplayed(!isMenuDisplayed)}
						ref={menuRef}>
						<BurgerMenu />
					</div>
				)}
			</div>

			<Menu
				setIsMenuDisplayed={setIsMenuDisplayed}
				user={user}
				logout={logout}
				navigate={navigate}
				isMenuDisplayed={isMenuDisplayed}
				dropDownRef={dropDownRef}
				menuItems={menuItems}
			/>
		</div>
	);

	function useOnClickOutside(ref, burgerRef, handler) {
		useEffect(() => {
			const listener = (event) => {
				// Do nothing if clicking ref's element or descendent elements
				if (
					!ref.current ||
					ref.current.contains(event.target) ||
					burgerRef.current.contains(event.target)
				) {
					return;
				}
				handler(event);
			};
			document.addEventListener('mousedown', listener);
			document.addEventListener('touchstart', listener);
			return () => {
				document.removeEventListener('mousedown', listener);
				document.removeEventListener('touchstart', listener);
			};
		}, [dropDownRef, menuRef, handler]);
	}
};

export default TopBar;
