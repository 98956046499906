import './Input.scss';
const Input = ({ id, placeholderText, type, onChange, optional }) => {
	return (
		<div className='input'>
			<input id={id} type={type} placeholder={placeholderText} onChange={onChange} />
			{optional && <label htmlFor={id}>{optional}</label>}
		</div>
	);
};

export default Input;
