const UserIcon = ({ dimensions }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={dimensions ? dimensions : '20'}
			height={dimensions ? dimensions : '20'}
			viewBox='0 0 24 24'
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'>
			<path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2'></path>
			<circle cx='12' cy='7' r='4'></circle>
		</svg>
	);
};

export default UserIcon;
