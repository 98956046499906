import React from 'react';
import './Navigation.scss';

const Navigation = ({ activeIndex, questionsNavList }) => {
	return (
		<div className='navigation'>
			{questionsNavList.map((li, index) => (
				<span key={index} className={index === activeIndex ? 'nav-item active' : 'nav-item'}>
					{li}
				</span>
			))}
		</div>
	);
};

export default Navigation;
