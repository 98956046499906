import { useNavigate } from 'react-router-dom';
import PriceIcon from '../../assets/SVG/PriceIcon';
import './Payment.scss';

const PaymentCancel = ({ text, isMobile }) => {
	const navigate = useNavigate();
	return (
		<div className='payment-cancel'>
			<div className='icon'>
				<svg
					width={isMobile ? '60' : '80'}
					height={isMobile ? '60' : '80'}
					viewBox='0 0 80 80'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<g clipPath='url(#clip0_19_815)'>
						<path
							d='M40.1562 0C18.1859 0 0 17.8734 0 39.8438C0 61.8141 18.1859 80 40.1562 80C62.1266 80 80 61.8141 80 39.8438C80 17.8734 62.1266 0 40.1562 0ZM59.8781 52.9359C61.7062 54.7641 61.7062 57.7375 59.8781 59.5672C58.0641 61.3797 55.0906 61.4094 53.2469 59.5672L40.1562 46.4719L26.7516 59.5687C24.9234 61.3969 21.95 61.3969 20.1203 59.5687C18.2922 57.7406 18.2922 54.7672 20.1203 52.9375L33.2141 39.8438L20.1203 26.75C18.2922 24.9203 18.2922 21.9469 20.1203 20.1187C21.95 18.2906 24.9234 18.2906 26.7516 20.1187L40.1562 33.2156L53.2469 20.1187C55.0719 18.2937 58.0453 18.2875 59.8781 20.1187C61.7062 21.9469 61.7062 24.9203 59.8781 26.75L46.7844 39.8438L59.8781 52.9359Z'
							fill='#E00751'
						/>
					</g>
					<defs>
						<clipPath id='clip0_19_815'>
							<rect width='80' height='80' fill='white' />
						</clipPath>
					</defs>
				</svg>
			</div>
			<div className='info'>
				<h1>{text.title}</h1>
				<p>{text.message}</p>
				<div className='navigate' role='button' onClick={() => navigate('/pricing')}>
					<PriceIcon />
					<span>{text.btn_text}</span>
				</div>
			</div>
		</div>
	);
};

export default PaymentCancel;
