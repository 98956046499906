import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import DownloadIcon from '../../assets/SVG/DownloadIcon';
import { useNavigate } from 'react-router-dom';
import MailIcon from '../../assets/SVG/MailIcon';
import './ImageTheatre.scss';

const ImageTheatre = ({
	theatreImageURL,
	isTheatreOpened,
	setIsTheatreOpened,
	isMobile,
	pageText,
}) => {
	const navigate = useNavigate();
	const handleCloseImageTheatre = () => {
		setIsTheatreOpened(false);
	};

	const handleImageDownload = () => {
		fetch(theatreImageURL, {
			method: 'GET',
			headers: {},
		})
			.then((response) => {
				response.arrayBuffer().then(function (buffer) {
					const url = window.URL.createObjectURL(new Blob([buffer]));
					const link = document.createElement('a');
					link.href = url;
					// link.download = "walls-kw";
					link.setAttribute('download', theatreImageURL); //or any other extension
					document.body.appendChild(link);
					link.click();
				});
			})
			.catch((err) => {
				//TODO: FIX ME -- show error box, image can not be downloded at the moment
			});
	};

	useEffect(() => {
		if (isTheatreOpened) {
			sessionStorage.setItem('theatreImageURL', theatreImageURL);
		} else {
			sessionStorage.setItem('theatreImageURL', '');
		}
	}, [isTheatreOpened]);

	return (
		isTheatreOpened && (
			<div className='image-theatre'>
				<div className='theatre-top'>
					<div className='close-theatre' onClick={handleCloseImageTheatre}>
						<FontAwesomeIcon className='icon' icon={faXmark} />
					</div>
				</div>
				<div className='theatre-content'>
					<div className='contact-section'>
						<h1>{pageText.title}</h1>
						<h2>{pageText.sub_title}</h2>
						<p>{pageText.invitation}</p>
						<div className='navigate' onClick={() => navigate('/contact')}>
							<MailIcon dimensions={'22'} />
							<span>{pageText.quotation_request}</span>
						</div>
					</div>
					<div className='image-container'>
						<div className='image-wrapper'>
							<img src={theatreImageURL} className='image' />
							<div className='download-icon' onClick={handleImageDownload}>
								<DownloadIcon dimensions={isMobile ? '16' : '16'} />
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

export default ImageTheatre;
