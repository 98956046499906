import React, { useEffect } from 'react';
import Select from '../../components/Select/Select';
import './NewGenerationForm.scss';

const NewGenerationForm = ({
	interiorResultFormAnswers,
	exteriorResultFormAnswers,
	appID,
	handleShowNewResultsClick,
	handleSelectChange,
	defaultSelectChoiceText,
	generateButtonText,
	isGenerateBtnEnabled,
	setIsGenerateBtnEnabled,
}) => {
	useEffect(() => {
		let counter = 0;
		if (appID.includes('interior')) {
			for (const key in interiorResultFormAnswers) {
				const element = interiorResultFormAnswers[key];
				counter = element?.selected?.id ? counter + 1 : counter - 1;
			}
		} else if (appID.includes('exterior')) {
			for (const key in exteriorResultFormAnswers) {
				const element = exteriorResultFormAnswers[key];
				counter = element?.selected?.id ? counter + 1 : counter - 1;
			}
		}
		setIsGenerateBtnEnabled(counter === 8 ? true : false);
	}, [appID, interiorResultFormAnswers, exteriorResultFormAnswers]);
	return (
		<div className='generate-form'>
			<div className='form'>
				<div className='select-container'>
					{(appID.includes('interior') || appID === '') && (
						<>
							<Select
								id='roomType-select'
								interiorResultFormAnswers={interiorResultFormAnswers.roomType}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
							<Select
								id='designStyle-select'
								interiorResultFormAnswers={interiorResultFormAnswers.designStyle}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
							<Select
								id='generalMood-select'
								interiorResultFormAnswers={interiorResultFormAnswers.generalMood}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
							<Select
								id='primaryColor-select'
								interiorResultFormAnswers={interiorResultFormAnswers.primaryColor}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
							<Select
								id='secondaryColor-select'
								interiorResultFormAnswers={interiorResultFormAnswers.secondaryColor}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
							<Select
								id='wallCladding-select'
								interiorResultFormAnswers={interiorResultFormAnswers.wallCladding}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
							<Select
								id='flooring-select'
								interiorResultFormAnswers={interiorResultFormAnswers.flooring}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
							<Select
								id='artWork-select'
								interiorResultFormAnswers={interiorResultFormAnswers.artWork}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
						</>
					)}
					{appID.includes('exterior') && (
						<>
							<Select
								id='buildingType-select'
								interiorResultFormAnswers={exteriorResultFormAnswers.buildingType}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
							<Select
								id='floorsNumber-select'
								interiorResultFormAnswers={exteriorResultFormAnswers.floorsNumber}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
							<Select
								id='archStyle-select'
								interiorResultFormAnswers={exteriorResultFormAnswers.archStyle}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
							<Select
								id='color-select'
								interiorResultFormAnswers={exteriorResultFormAnswers.color}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
							<Select
								id='primaryMaterial-select'
								interiorResultFormAnswers={exteriorResultFormAnswers.primaryMaterial}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
							<Select
								id='secondaryMaterial-select'
								interiorResultFormAnswers={exteriorResultFormAnswers.secondaryMaterial}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
							<Select
								id='surroundings-select'
								interiorResultFormAnswers={exteriorResultFormAnswers.surroundings}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
							<Select
								id='landscape-select'
								interiorResultFormAnswers={exteriorResultFormAnswers.landscape}
								handleChange={handleSelectChange}
								defaultSelectChoiceText={defaultSelectChoiceText}
							/>
						</>
					)}
				</div>
				<div className='button-container'>
					<div
						className={`result-button-wrapper${isGenerateBtnEnabled ? ' enabled' : ' disabled'}`}
						onClick={handleShowNewResultsClick}>
						<span className='button-text'>{generateButtonText}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewGenerationForm;
