import './Select.scss';

const Select = ({ id, interiorResultFormAnswers, handleChange, defaultSelectChoiceText }) => {
	return (
		<div className='select-wrapper'>
			<label htmlFor={id} className='select-label'>
				{interiorResultFormAnswers?.labelText}
			</label>
			<div className='input-wrapper'>
				<select
					className='select-input'
					id={id}
					value={
						interiorResultFormAnswers && interiorResultFormAnswers?.selected
							? interiorResultFormAnswers.selected.name
							: 'Select a choice'
					}
					onChange={handleChange}>
					<option value='Select a choice' disabled className='default-option'>
						{defaultSelectChoiceText}
					</option>
					{interiorResultFormAnswers?.options.map((opt) => (
						<option key={opt.id} id={opt.id} value={opt.name}>
							{opt.text}
						</option>
					))}
				</select>
				<svg className='icon' xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 512 512'>
					<path d='M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z' />
				</svg>
			</div>
		</div>
	);
};

export default Select;
