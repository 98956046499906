export const interiorDesignStyles = [
	'../images/interior/classic.png',
	'../images/interior/modern.png',
	'../images/interior/bohemian.png',
	'../images/interior/contermorary.png',
	'../images/interior/minimal.png',
	'../images/interior/industrial.png',
	'../images/interior/vintage.png',
	'../images/interior/artdeco.png',
	'../images/interior/rustic.png',
	'../images/interior/mediterranean.png',
];

export const exteriorDesignStyles = [
	'../images/exterior/contemporary.png',
	'../images/exterior/modern.png',
	'../images/exterior/hightech.png',
	'../images/exterior/minimal.png',
	'../images/exterior/neoclassic.png',
	'../images/exterior/neofuturistic.png',
];
