import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../components/Loading/Loading';
import './Pricing.scss';

const Pricing = ({ isMobile, itemsList, setItemsList }) => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const loadingMessage = 'Redirecting to the payment page...';
	const handleListItemClick = (evt) => {
		const id = evt.target.id;
		const clonedItemsList = [...itemsList];
		clonedItemsList.map((item) => (item.selected = item.id === parseInt(id) ? true : false));
		setItemsList(clonedItemsList);
	};

	useEffect(() => {
		sessionStorage.setItem('path', window.location.pathname);
	}, []);

	const handleBuyPlan = (id) => {
		setIsLoading(true);
		axios
			.post(`${process.env.REACT_APP_API_URL}/payment`, { id: id })
			.then((response) => {
				window.location = response.data.url;
				setIsLoading(false);
				console.log(response);
			})
			.catch((error) => {});
	};

	return (
		<div className='pricing'>
			<div className='pricing-container'>
				<div className='pricing-top'>
					<h1>Pick up a payment plan to bring your wildest ideas to life with stunning visuals</h1>
					{isMobile && (
						<div className='items-list'>
							{itemsList.map((item) => (
								<span
									key={item.id}
									id={item.id}
									className={`list-item${item.selected ? ' selected' : ''}`}
									onClick={(e) => handleListItemClick(e)}>
									{item.text}
								</span>
							))}
						</div>
					)}
				</div>
				<div className='pricing-bottom'>
					{itemsList[0].selected && (
						<div className='pricing-wrapper'>
							<div className='price-item'>
								<div className='item-top'>
									<div className='item-top-title'>
										<span className='target-title'>Free</span>
									</div>
									<div className='item-top-price'>
										<span className='price'>$0</span>
										<span>monthly</span>
									</div>
									<div className='item-top-subtitle'>Give our AI a try for free</div>
								</div>
								<div className='item-bottom'>
									<div className='item-top-details'>
										<ul className='details-list'>
											<li>15 Creations</li>
											<li>Interior AI</li>
											<li>Exterior AI</li>
										</ul>
									</div>
									<div className='buy-item-btn' role='button'>
										<span>Sign up</span>
									</div>
								</div>
							</div>
						</div>
					)}
					{itemsList[1].selected && (
						<div className='pricing-wrapper'>
							<div className='price-item'>
								<div className='item-top'>
									<div className='item-top-title'>
										<span className='target-title'>Pro</span>
									</div>
									<div className='item-top-price'>
										<span className='price'>$5</span>
										<span>monthly</span>
									</div>
									<div className='item-top-subtitle'>For newbies and individuals</div>
								</div>
								<div className='item-bottom'>
									<div className='item-top-details'>
										<ul className='details-list'>
											<li>100 Creations</li>
											<li>Interior AI</li>
											<li>Exterior AI</li>
										</ul>
									</div>
									<div
										className='buy-item-btn'
										role='button'
										onClick={() => handleBuyPlan(itemsList[1].id)}>
										<span>Buy plan</span>
									</div>
								</div>
							</div>
						</div>
					)}
					{itemsList[2].selected && (
						<div className='pricing-wrapper'>
							<div className='price-item'>
								<div className='item-top'>
									<div className='item-top-title'>
										<span className='target-title'>Teams</span>
										<div className='popular-item'>
											<span>Popular</span>
										</div>
									</div>
									<div className='item-top-price'>
										<span className='price'>$10</span>
										<span>monthly</span>
									</div>
									<div className='item-top-subtitle'>For newbies and individuals</div>
								</div>
								<div className='item-bottom'>
									<div className='item-top-details'>
										<ul className='details-list'>
											<li>1000 Creations</li>
											<li>Interior AI</li>
											<li>Exterior AI</li>
										</ul>
									</div>
									<div
										className='buy-item-btn'
										role='button'
										onClick={() => handleBuyPlan(itemsList[2].id)}>
										<span>Buy plan</span>
									</div>
								</div>
							</div>
						</div>
					)}
					{itemsList[3].selected && (
						<div className='pricing-wrapper'>
							<div className='price-item'>
								<div className='item-top'>
									<div className='item-top-title'>
										<span className='target-title'>Business</span>
									</div>
									<div className='item-top-price'>
										<span className='price'>$100</span>
										<span>monthly</span>
									</div>
									<div className='item-top-subtitle'>For newbies and individuals</div>
								</div>
								<div className='item-bottom'>
									<div className='item-top-details'>
										<ul className='details-list'>
											<li>10.000 Creations</li>
											<li>Interior AI</li>
											<li>Exterior AI</li>
										</ul>
									</div>
									<div
										className='buy-item-btn'
										role='button'
										onClick={() => handleBuyPlan(itemsList[3].id)}>
										<span>Buy Plan</span>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{isLoading && <Loading loadingMessage={loadingMessage} from='pricing' />}
		</div>
	);
};

export default Pricing;
