import React, { useState, useEffect } from 'react';
import ImageTheatre from '../../components/ImageTheatre/ImageTheatre';
import { useAuthContext } from '../../hooks/useAuthContext';
import axios from 'axios';
import './Profile.scss';

const Profile = ({ isMobile, allowedNumberOfSubmits, pageText, user }) => {
	const API_URL = process.env.REACT_APP_API_URL;

	const { client } = useAuthContext();
	const [numberOfSubmits, setNumberOfSubmits] = useState('');
	// const [isActiveUser, setIsActiveUser] = useState(false);
	const [userImages, setUserImages] = useState([]);
	const [isTheatreOpened, setIsTheatreOpened] = useState(false);
	const [theatreImageURL, setTheatreImageURL] = useState('');
	// const [user, setUser] = useState({});

	const handleMainImageClick = (url) => {
		setTheatreImageURL(url);
		setIsTheatreOpened(true);
	};

	const getDataClient = (id) => {
		axios.get(`${API_URL}/api/data/dataClients/client/${id}`).then((res) => {
			if (res.data.dataclient !== 0) {
				setNumberOfSubmits(res.data.dataclient[0].nbSubmit);
				// setIsActiveUser(res.data.dataclient[0].user.active);
				setUserImages([...res.data.dataclient[0].images]);
				// setUser(res.data.dataclient[0].user);
			}
		});
	};

	useEffect(() => {
		if (client) {
			getDataClient(client.id);
		}
	}, [client]);

	useEffect(() => {
		sessionStorage.setItem('path', window.location.pathname);
	}, []);

	return (
		<div className='profile-page'>
			<div className='title'>
				<h1>{pageText.profile}</h1>
			</div>
			<div className='profile-data'>
				<div className='data-title'>
					<span>{pageText.details}</span>
				</div>
				<div className='user-info'>
					<div className='info'>
						<p>
							{pageText.name} {isMobile ? ': ' : ''}
						</p>
						<span>
							{user?.firstName} {user?.lastName}
						</span>
					</div>
					<div className='info'>
						<p>
							{pageText.email} {isMobile ? ': ' : ''}
						</p>
						<span>{user?.email}</span>
					</div>
					<div className='info'>
						<p>
							{pageText.number_of_generations} {isMobile ? ':' : ''}
						</p>
						<span>{numberOfSubmits ? numberOfSubmits : pageText.not_available}</span>
					</div>
					<div className='info'>
						<p>
							{pageText.generations_remaining} {isMobile ? ':' : ''}
						</p>
						<span>
							{user.active ? pageText.unlimited : allowedNumberOfSubmits - numberOfSubmits}
						</span>
					</div>
					<div className='info'>
						<p>
							{pageText.super_user} {isMobile ? ':' : ''}
						</p>
						<span>{user.active ? pageText.yes : pageText.no}</span>
					</div>
				</div>
			</div>
			<div className='profile-images'>
				<div className='images-title'>
					<span>{pageText.generated_imgs}</span>
				</div>
				<div className='images-grid'>
					{userImages.map((img, index) => (
						<div
							className={`img-wrapper ${index === 1 ? 'square' : ''}`}
							key={`profile-img${index}`}
							onClick={() => handleMainImageClick(img)}>
							<img
								className='img'
								src={img}
								onError={(i) => (i.target.src = '../images/img-na.png')}
							/>
						</div>
					))}
				</div>
			</div>
			<ImageTheatre
				theatreImageURL={theatreImageURL}
				isTheatreOpened={isTheatreOpened}
				setIsTheatreOpened={setIsTheatreOpened}
				pageText={pageText.theatre_text}
			/>
		</div>
	);
};

export default Profile;
