import { lang } from './Languages';

export const getTranslations = (languageCode) => {
	const {
		interior_app,
		exterior_app,
		landing_general_text,
		results_general_text,
		profile_general_text,
		contact_general_text,
		apps_text,
		menu_items,
		common,
		pricing_text,
		payment_success_text,
		payment_cancel_text,
		login_text,
		signup_text,
	} = lang[languageCode];

	const { interior_questions_data, interior_nav_items } = interior_app;

	const {
		commercial_room_type,
		room_type,
		design_style,
		general_mood,
		primary_color,
		secondary_color,
		wall_cladding,
		flooring,
		art_work,
	} = interior_questions_data;

	const { exterior_questions_data, exterior_nav_items } = exterior_app;
	const {
		commercial_building_type,
		building_type,
		floors_number,
		arch_style,
		color_scheme,
		primary_material,
		secondary_material,
		surroundings,
		landscape,
	} = exterior_questions_data;

	return {
		interior_data: {
			interior_questions_data: [
				{
					id: 'roomType',
					title: room_type.title,
					text: room_type.text,
					choices: [
						{ id: 'living_room', text: room_type.living_room, name: 'living room' },
						{ id: 'dining_room', text: room_type.dining_room, name: 'dining room' },
						{ id: 'bathroom', text: room_type.bathroom, name: 'bathroom' },
						{ id: 'kitchen', text: room_type.kitchen, name: 'kitchen' },
						{ id: 'bedroom', text: room_type.bedroom, name: 'bedroom' },
						{ id: 'rooftop', text: room_type.rooftop, name: 'rooftop' },
						{ id: 'kids_room', text: room_type.kids_room, name: 'kids room' },
						{ id: 'home_office', text: room_type.home_office, name: 'home office' },
						{ id: 'home_gym', text: room_type.home_gym, name: 'home gym' },
						{ id: 'home_cinema', text: room_type.home_cinema, name: 'home cinema' },
					],
				},
				{
					id: 'designStyle',
					title: design_style.title,
					text: design_style.text,
					choices: [
						{ id: 'neo_classic', text: design_style.neo_classic, name: 'neoclassic' },
						{ id: 'contemporary', text: design_style.contemporary, name: 'contemporary' },
						{ id: 'bohemian', text: design_style.bohemian, name: 'bohemian' },
						{ id: 'minimal', text: design_style.minimal, name: 'minimal' },
						{ id: 'industrial', text: design_style.industrial, name: 'industrial' },
						{ id: 'rustic', text: design_style.rustic, name: 'rustic' },
						{ id: 'vintage', text: design_style.vintage, name: 'vintage' },
						{ id: 'modern', text: design_style.modern, name: 'modern' },
						{ id: 'artdeco', text: design_style.artdeco, name: 'artdeco' },
						{ id: 'mediterranean', text: design_style.mediterranean, name: 'mediterranean' },
					],
				},
				{
					id: 'generalMood',
					title: general_mood.title,
					text: general_mood.text,
					choices: [
						{ id: 'cozy_warm', text: general_mood.cozy_warm, name: 'cozy_warm' },
						{ id: 'peaceful_calm', text: general_mood.peaceful_calm, name: 'peaceful_calm' },
						{
							id: 'detailed_sophisticated',
							text: general_mood.detailed_sophisticated,
							name: 'detailed_sophisticated',
						},
						{
							id: 'vibrant_energetic',
							text: general_mood.vibrant_energetic,
							name: 'vibrant_energetic',
						},
						{ id: 'calm_relaxing', text: general_mood.calm_relaxing, name: 'calm_relaxing' },
						{ id: 'spacious_open', text: general_mood.spacious_open, name: 'spacious_open' },
						{
							id: 'traditional_comfortable',
							text: general_mood.traditional_comfortable,
							name: 'traditional_comfortable',
						},
						{
							id: 'artistic_inspirational',
							text: general_mood.artistic_inspirational,
							name: 'artistic_inspirational',
						},
						{
							id: 'modern_minimalistic',
							text: general_mood.modern_minimalistic,
							name: 'modern_minimalistic',
						},
						{ id: 'rustic_natural', text: general_mood.rustic_natural, name: 'rustic_natural' },
					],
				},
				{
					id: 'primaryColor',
					title: primary_color.title,
					text: primary_color.text,
					choices: [
						{ id: 'prc_white', text: primary_color.prc_white, name: 'white' },
						{ id: 'prc_grey', text: primary_color.prc_grey, name: 'grey' },
						{ id: 'prc_beige', text: primary_color.prc_beige, name: 'beige tan' },
						{ id: 'prc_navy', text: primary_color.prc_navy, name: 'navy' },
						{ id: 'prc_black', text: primary_color.prc_black, name: 'black' },
						{ id: 'prc_red', text: primary_color.prc_red, name: 'red' },
						{ id: 'prc_olive_green', text: primary_color.prc_olive_green, name: 'oliver green' },
						{ id: 'prc_pink', text: primary_color.prc_pink, name: 'pink' },
						{ id: 'prc_terracotta', text: primary_color.prc_terracotta, name: 'terracotta' },
						{ id: 'prc_dark_yellow', text: primary_color.prc_dark_yellow, name: 'dark yellow' },
						{ id: 'prc_mocha', text: primary_color.prc_mocha, name: 'mocha' },
						{ id: 'prc_purple', text: primary_color.prc_purple, name: 'purple' },
					],
				},
				{
					id: 'secondaryColor',
					title: secondary_color.title,
					text: secondary_color.text,
					choices: [
						{ id: 'sec_white', text: secondary_color.sec_white, name: 'white' },
						{ id: 'sec_grey', text: secondary_color.sec_grey, name: 'grey' },
						{ id: 'sec_beige', text: secondary_color.sec_beige, name: 'beige tan' },
						{ id: 'sec_navy', text: secondary_color.sec_navy, name: 'navy' },
						{ id: 'sec_black', text: secondary_color.sec_black, name: 'black' },
						{ id: 'sec_red', text: secondary_color.sec_red, name: 'red' },
						{ id: 'sec_olive_green', text: secondary_color.sec_olive_green, name: 'oliver green' },
						{ id: 'sec_pink', text: secondary_color.sec_pink, name: 'pink' },
						{ id: 'sec_terracotta', text: secondary_color.sec_terracotta, name: 'terracotta' },
						{ id: 'sec_dark_yellow', text: secondary_color.sec_dark_yellow, name: 'dark yellow' },
						{ id: 'sec_mocha', text: secondary_color.sec_mocha, name: 'mocha' },
						{ id: 'sec_purple', text: secondary_color.sec_purple, name: 'purple' },
					],
				},
				{
					id: 'wallCladding',
					title: wall_cladding.title,
					text: wall_cladding.text,
					choices: [
						{ id: 'wc_marble', text: wall_cladding.wc_marble, name: 'marble' },
						{ id: 'wc_brick', text: wall_cladding.wc_brick, name: 'brick' },
						{ id: 'wc_woodpanels', text: wall_cladding.wc_woodpanels, name: 'wood_panels' },
						{ id: 'wc_paint', text: wall_cladding.wc_paint, name: 'paint' },
						{ id: 'wc_molding', text: wall_cladding.wc_molding, name: 'french_molding' },
						{ id: 'wc_microcement', text: wall_cladding.wc_microcement, name: 'microcement' },
						{ id: 'wc_wallpaper', text: wall_cladding.wc_wallpaper, name: 'wallpaper' },
						{ id: 'wc_stucco', text: wall_cladding.wc_stucco, name: 'stucco' },
					],
				},
				{
					id: 'flooring',
					title: flooring.title,
					text: flooring.text,
					choices: [
						{ id: 'fl_marble', text: flooring.fl_marble, name: 'marble' },
						{ id: 'fl_vinyl', text: flooring.fl_vinyl, name: 'vinyl' },
						{ id: 'fl_parquet', text: flooring.fl_parquet, name: 'parquet' },
						{ id: 'fl_tiles', text: flooring.fl_tiles, name: 'tiles' },
						{ id: 'fl_carpet', text: flooring.fl_carpet, name: 'carpet' },
						{ id: 'fl_granite', text: flooring.fl_granite, name: 'granite' },
						{ id: 'fl_porcelain', text: flooring.fl_porcelain, name: 'porcelain' },
						{ id: 'fl_microcement', text: flooring.fl_microcement, name: 'microcement' },
					],
				},
				{
					id: 'artWork',
					title: art_work.title,
					text: art_work.text,
					choices: [
						{ id: 'art_realistic', text: art_work.art_realistic, name: 'realistic' },
						{ id: 'art_bold', text: art_work.art_bold, name: 'bold' },
						{ id: 'art_simple', text: art_work.art_simple, name: 'simple' },
						{ id: 'art_abstract', text: art_work.art_abstract, name: 'abstract' },
						{ id: 'art_impressionist', text: art_work.art_impressionist, name: 'impressionist' },
						{ id: 'art_cubist', text: art_work.art_cubist, name: 'cubist' },
						{ id: 'art_pop', text: art_work.art_pop, name: 'pop' },
						{ id: 'art_contemporary', text: art_work.art_contemporary, name: 'contemporary' },
					],
				},
			],
			interior_nav_items,
			interior_commercial_room_type: {
				id: 'roomType',
				title: commercial_room_type.title,
				text: commercial_room_type.text,
				choices: [
					{ id: 'retail_shop', text: commercial_room_type.retail_shop, name: 'retail_shop' },
					{ id: 'coffee_shop', text: commercial_room_type.coffee_shop, name: 'coffee_shop' },
					{ id: 'supermarket', text: commercial_room_type.supermarket, name: 'supermarket' },
					{ id: 'bakery', text: commercial_room_type.bakery, name: 'bakery' },
					{ id: 'barbershop', text: commercial_room_type.barbershop, name: 'barbershop' },
					{ id: 'bookstore', text: commercial_room_type.bookstore, name: 'bookstore' },
					{
						id: 'fast_food_rest',
						text: commercial_room_type.fast_food_rest,
						name: 'fast_food_rest',
					},
					{
						id: 'fine_dine_rest',
						text: commercial_room_type.fine_dine_rest,
						name: 'fine_dine_rest',
					},
					{
						id: 'hair_nail_salon',
						text: commercial_room_type.hair_nail_salon,
						name: 'hair_nail_salon',
					},
					{ id: 'jewelry_store', text: commercial_room_type.jewelry_store, name: 'jewelry_store' },
				],
			},
		},
		exterior_data: {
			exterior_questions_data: [
				{
					id: 'buildingType',
					title: building_type.title,
					text: building_type.text,
					choices: [
						{
							id: 'bt_single_family_residence',
							text: building_type.bt_single_family_residence,
							name: 'single_family',
						},
						{
							id: 'bt_apartments_building',
							text: building_type.bt_apartments_building,
							name: 'apartment_building',
						},
						{
							id: 'bt_private_chalet',
							text: building_type.bt_private_chalet,
							name: 'private_chalet',
						},
						{
							id: 'bt_highrise_residential_building',
							text: building_type.bt_highrise_residential_building,
							name: 'highrise',
						},
						{ id: 'bt_farm_house', text: building_type.bt_farm_house, name: 'farm_house' },
						{ id: 'bt_hotel', text: building_type.bt_hotel, name: 'hotel' },
					],
				},
				{
					id: 'floorsNumber',
					title: floors_number.title,
					text: floors_number.text,
					choices: [
						{
							id: 'fn_one_two',
							text: floors_number.fn_one_two,
							name: '1to2',
						},
						{
							id: 'fn_two_three',
							text: floors_number.fn_two_three,
							name: '2to3',
						},
						{
							id: 'fn_three_four',
							text: floors_number.fn_three_four,
							name: '3to4',
						},
						{
							id: 'fn_four_five',
							text: floors_number.fn_four_five,
							name: '4to5',
						},
						{ id: 'fn_five_six', text: floors_number.fn_five_six, name: '5to6' },
						{ id: 'fn_more_six', text: floors_number.fn_more_six, name: 'more6' },
					],
				},
				{
					id: 'archStyle',
					title: arch_style.title,
					text: arch_style.text,
					choices: [
						{
							id: 'as_neo_futurist',
							text: arch_style.as_neo_futurist,
							name: 'neo_futurist',
						},
						{
							id: 'as_neo_classical',
							text: arch_style.as_neo_classical,
							name: 'neo_classical',
						},
						{
							id: 'as_high_tech',
							text: arch_style.as_high_tech,
							name: 'hightech',
						},
						{
							id: 'as_contemporary',
							text: arch_style.as_contemporary,
							name: 'contemporary',
						},
						{ id: 'as_minimal', text: arch_style.as_minimal, name: 'minimal' },
						{ id: 'as_modern', text: arch_style.as_modern, name: 'modern' },
					],
				},
				{
					id: 'color',
					title: color_scheme.title,
					text: color_scheme.text,
					choices: [
						{
							id: 'cs_earthy_tones',
							text: color_scheme.cs_earthy_tones,
							name: 'earthy',
						},
						{
							id: 'cs_cool_tones',
							text: color_scheme.cs_cool_tones,
							name: 'cool',
						},
						{
							id: 'cs_warm_tones',
							text: color_scheme.cs_warm_tones,
							name: 'warm',
						},
						{
							id: 'cs_white_bright',
							text: color_scheme.cs_white_bright,
							name: 'white & bright',
						},
						{ id: 'cs_monochromatic', text: color_scheme.cs_monochromatic, name: 'monochromatic' },
						{ id: 'cs_greys', text: color_scheme.cs_greys, name: 'grey' },
					],
				},
				{
					id: 'primaryMaterial',
					title: primary_material.title,
					text: primary_material.text,
					choices: [
						{
							id: 'pm_concrete',
							text: primary_material.pm_concrete,
							name: 'concrete',
						},
						{
							id: 'pm_natural_stone',
							text: primary_material.pm_natural_stone,
							name: 'natural stones',
						},
						{
							id: 'pm_plain_paint',
							text: primary_material.pm_plain_paint,
							name: 'paint',
						},
						{
							id: 'pm_patterned_paint',
							text: primary_material.pm_patterned_paint,
							name: 'patterned paint',
						},
						{
							id: 'pm_metal_cladding',
							text: primary_material.pm_metal_cladding,
							name: 'metal cladding',
						},
						{
							id: '	pm_aluminum_cladding',
							text: primary_material.pm_aluminum_cladding,
							name: 'aluminum cladding',
						},
						{
							id: '	pm_patterns_perforation',
							text: primary_material.pm_patterns_perforation,
							name: 'patterns & perforation',
						},
						{
							id: '	pm_breeze_blocks',
							text: primary_material.pm_breeze_blocks,
							name: 'breeze blocks',
						},
						{
							id: '	pm_wood',
							text: primary_material.pm_wood,
							name: 'wood',
						},
						{
							id: '	pm_glass',
							text: primary_material.pm_glass,
							name: 'glass',
						},
					],
				},
				{
					id: 'secondaryMaterial',
					title: secondary_material.title,
					text: secondary_material.text,
					choices: [
						{
							id: 'sm_concrete',
							text: secondary_material.sm_concrete,
							name: 'concrete',
						},
						{
							id: 'sm_natural_stone',
							text: secondary_material.sm_natural_stone,
							name: 'natural stones',
						},
						{
							id: 'sm_plain_paint',
							text: secondary_material.sm_plain_paint,
							name: 'plain paint',
						},
						{
							id: 'sm_patterned_paint',
							text: secondary_material.sm_patterned_paint,
							name: 'patterned paint',
						},
						{
							id: 'sm_metal_cladding',
							text: secondary_material.sm_metal_cladding,
							name: 'metal cladding',
						},
						{
							id: '	sm_aluminum_cladding',
							text: secondary_material.sm_aluminum_cladding,
							name: 'aluminum cladding',
						},
						{
							id: '	sm_patterns_perforation',
							text: secondary_material.sm_patterns_perforation,
							name: 'patterns & perforation',
						},
						{
							id: '	sm_breeze_blocks',
							text: secondary_material.sm_breeze_blocks,
							name: 'breeze blocks',
						},
						{
							id: '	sm_wood',
							text: secondary_material.sm_wood,
							name: 'wood',
						},
						{
							id: '	sm_glass',
							text: secondary_material.sm_glass,
							name: 'glass',
						},
					],
				},
				{
					id: 'surroundings',
					title: surroundings.title,
					text: surroundings.text,
					choices: [
						{
							id: 'su_urban',
							text: surroundings.su_urban,
							name: 'urban',
						},
						{
							id: 'su_city_center',
							text: surroundings.su_city_center,
							name: 'city_center',
						},
						{
							id: 'su_desert',
							text: surroundings.su_desert,
							name: 'desert',
						},
						{
							id: 'su_farms',
							text: surroundings.su_farms,
							name: 'farms',
						},
						{ id: 'su_tropical', text: surroundings.su_tropical, name: 'tropical' },
						{ id: '	su_waterfront', text: surroundings.su_waterfront, name: 'waterfront' },
					],
				},
				{
					id: 'landscape',
					title: landscape.title,
					text: landscape.text,
					choices: [
						{
							id: 'ls_pool_garden',
							text: landscape.ls_pool_garden,
							name: 'pool_garden',
						},
						{
							id: 'ls_rooftop_garden',
							text: landscape.ls_rooftop_garden,
							name: 'rooftop_garden',
						},
						{
							id: 'ls_exotic_garden',
							text: landscape.ls_exotic_garden,
							name: 'exotic_garden',
						},
						{
							id: 'ls_edible_garden',
							text: landscape.ls_edible_garden,
							name: 'edible_garden',
						},
						{ id: 'ls_rocks_garden', text: landscape.ls_rocks_garden, name: 'rocks_garden' },
						{ id: 'ls_water_fountain', text: landscape.ls_water_fountain, name: 'water_fountain' },
					],
				},
			],
			exterior_nav_items,
			exterior_commercial_building_type: {
				id: 'buildingType',
				title: commercial_building_type.title,
				text: commercial_building_type.text,
				choices: [
					{
						id: 'cbt_industrial',
						text: commercial_building_type.cbt_industrial,
						name: 'cbt_industrial',
					},
					{
						id: 'cbt_skyscraper',
						text: commercial_building_type.cbt_skyscraper,
						name: 'cbt_skyscraper',
					},
					{
						id: 'cbt_office_tower',
						text: commercial_building_type.cbt_office_tower,
						name: 'cbt_office_tower',
					},
					{
						id: 'cbt_retail_complex',
						text: commercial_building_type.cbt_retail_complex,
						name: 'cbt_retail_complex',
					},
					{
						id: 'cbt_municipal_building',
						text: commercial_building_type.cbt_municipal_building,
						name: 'cbt_municipal_building',
					},
					{
						id: 'cbt_governmental_building',
						text: commercial_building_type.cbt_governmental_building,
						name: 'cbt_governmental_building',
					},
					{
						id: 'cbt_hospital_building',
						text: commercial_building_type.cbt_hospital_building,
						name: 'cbt_hospital_building',
					},
					{
						id: 'cbt_mixed_building',
						text: commercial_building_type.cbt_mixed_building,
						name: 'cbt_mixed_building',
					},
				],
			},
		},
		menu_items,
		results_general_text,
		profile_general_text,
		landing_general_text,
		contact_general_text,
		apps_text,
		pricing_text,
		payment_success_text,
		payment_cancel_text,
		login_text,
		signup_text,
		common,
	};
};
