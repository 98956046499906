export const lang = {
	AR: {
		interior_app: {
			interior_questions_data: {
				commercial_room_type: {
					title: 'نوع الفراغ',
					text: 'ما هو نوع الفراغ الذي تريد تصميمه؟',
					retail_shop: 'متجر بيع',
					coffee_shop: 'مقهى',
					supermarket: 'سوبرماركت',
					bakery: 'مخبز',
					barbershop: 'صالون حلاقة',
					bookstore: 'مكتبة',
					fast_food_rest: 'مطعم الوجبات السريعة',
					fine_dine_rest: 'مطعم طعام فاخر',
					hair_nail_salon: 'صالون للشعر والأظافر',
					jewelry_store: 'متجر مجوهرات',
				},
				room_type: {
					title: 'نوع الفراغ',
					text: 'ما هو نوع الفراغ الذي تريد تصميمه؟',
					living_room: 'صالة استقبال',
					dining_room: 'غرفة طعام',
					bathroom: 'دورة المياه',
					kitchen: 'مطبخ',
					bedroom: 'غرفة نوم',
					rooftop: 'السطح',
					kids_room: 'غرفة الأطفال',
					home_office: 'مكتب منزلي',
					home_gym: 'نادي منزلي',
					home_cinema: 'سينما منزليه',
				},
				design_style: {
					title: 'نمط التصميم',
					text: 'ما هو نمط التصميم المفضل لديك؟',
					neo_classic: 'كلاسيكي جديد',
					contemporary: 'معاصر',
					bohemian: 'متحرر - بوهيمي',
					minimal: 'بسيط',
					industrial: 'صناعي',
					rustic: 'ريفي',
					vintage: 'عتيق ',
					modern: 'حديث ',
					artdeco: 'فن زخرفي - ارت ديكو',
					mediterranean: 'البحر المتوسط',
				},
				general_mood: {
					title: 'الشعور',
					text: 'ما الشعور الذي تريد ان تعطيه لتصميمك؟',
					cozy_warm: 'دافىء و مريح',
					peaceful_calm: 'هادئ',
					detailed_sophisticated: 'تفصيلي ومتطور',
					vibrant_energetic: 'حيوي',
					calm_relaxing: 'الهدوء والاسترخاء',
					spacious_open: 'فسيح ومفتوح',
					traditional_comfortable: 'تقليدي ومريح',
					artistic_inspirational: 'فني وملهم',
					modern_minimalistic: 'حديث وبسيط',
					rustic_natural: 'ريفي وطبيعي',
				},
				primary_color: {
					title: 'اللون الأساسي',
					text: 'ما هو اللون الأساسي الذي تفضل أن تراه في تصميمك؟',
					prc_white: 'ابيض',
					prc_grey: 'رمادي',
					prc_beige: 'بني فاتح',
					prc_navy: 'ازرق نيلي',
					prc_black: 'اسود',
					prc_red: 'أحمر',
					prc_olive_green: 'اخضر زيتوني',
					prc_pink: 'وردي',
					prc_terracotta: 'طيني/تيراكوتا',
					prc_dark_yellow: 'اصفر غامق',
					prc_mocha: 'موكا',
					prc_purple: 'بنفسجي',
				},
				secondary_color: {
					title: 'اللون الثانوي',
					text: 'ما هو اللون الثانوي الذي تفضل أن تراه في تصميمك؟',
					sec_white: 'ابيض',
					sec_grey: 'رمادي',
					sec_beige: 'بني فاتح',
					sec_navy: 'ازرق نيلي',
					sec_black: 'اسود',
					sec_red: 'أحمر',
					sec_olive_green: 'اخضر زيتوني',
					sec_pink: 'وردي',
					sec_terracotta: 'طيني/تيراكوتا',
					sec_dark_yellow: 'اصفر غامق',
					sec_mocha: 'موكا',
					sec_purple: 'بنفسجي',
				},
				wall_cladding: {
					title: 'مادة الجدران',
					text: 'ما هو نوع كساء الجدران لذي تفضله لتصميمك ؟',
					wc_marble: 'رخام',
					wc_brick: 'قالب طوب',
					wc_paint: 'دهان',
					wc_woodpanels: ' الواح خشبية',
					wc_molding: 'طراز فرنسي',
					wc_microcement: 'أسمنت',
					wc_wallpaper: 'ورق جدران',
					wc_stucco: 'جص',
				},
				flooring: {
					title: 'الارضيات',
					text: 'ما هو تبليط الأرضيات الذي تفضله لتصميمك؟',
					fl_marble: 'رخام',
					fl_vinyl: 'فينيل',
					fl_parquet: 'خشب (باركيه)',
					fl_tiles: 'بلاط',
					fl_carpet: 'سجاد',
					fl_granite: 'جرانيت',
					fl_porcelain: 'بورسلين',
					fl_microcement: 'خرسانه',
				},
				art_work: {
					title: 'فن للحواىْط',
					text: 'ما هو نوع التحف الفنية لذي تفضله لتصميمك ؟',
					art_realistic: 'واقعي',
					art_bold: 'جريء',
					art_simple: 'بسيط',
					art_abstract: 'تجريدي',
					art_impressionist: 'انطباعي',
					art_cubist: 'تكعيبي',
					art_pop: 'شعبي',
					art_contemporary: 'معاصر',
				},
			},
			interior_nav_items: [
				'الفراغ',
				'التصميم',
				'الشعور',
				'اللون الأساسي',
				'اللون الثانوي',
				'الجدران',
				'الارضيات',
				'الفن',
			],
		},
		exterior_app: {
			exterior_questions_data: {
				commercial_building_type: {
					title: 'نوع البناء',
					text: 'ما نوع المبنى الذي تريد تصميمه؟',
					cbt_industrial: 'مستودع صناعي',
					cbt_skyscraper: 'ناطحة سحاب',
					cbt_office_tower: 'برج مكاتب',
					cbt_retail_complex: 'مجمع تجاري',
					cbt_municipal_building: 'مبنى بلدية',
					cbt_governmental_building: 'مبنى حكومي',
					cbt_hospital_building: 'مستشفى',
					cbt_mixed_building: 'مبنى متعدد الاستخدامات',
				},
				building_type: {
					title: 'نوع البناء',
					text: 'ما نوع المبنى الذي تريد تصميمه؟',
					bt_single_family_residence: 'منزل مستقل',
					bt_apartments_building: 'مبنى شقق',
					bt_private_chalet: 'شاليه',
					bt_highrise_residential_building: 'برج سكني عالي',
					bt_farm_house: 'منزل مزرعة',
					bt_hotel: 'فندق',
				},
				floors_number: {
					title: 'عدد الطوابق',
					text: 'ما هو عدد الطوابق التي تريدها في المبنى الخاص بك؟',
					fn_one_two: 'طابق واحد الى اثنين',
					fn_two_three: 'طابقين الى ثلاثة',
					fn_three_four: 'طابقين الى ثلاثة',
					fn_four_five: 'أربعة الى خمس طوابق',
					fn_five_six: 'خمس الى ستة طوابق',
					fn_more_six: 'اكثر من ست طوابق',
				},
				arch_style: {
					title: 'الطراز المعماري',
					text: 'ما هو أسلوبك المعماري المفضل؟',
					as_neo_futurist: 'مستقبلي',
					as_neo_classical: 'نيو كلاسيك',
					as_high_tech: 'تقنية عالية',
					as_contemporary: 'معاصر',
					as_minimal: 'بسيط',
					as_modern: 'حديث',
				},
				color_scheme: {
					title: 'مخطط الألوان',
					text: 'ما هو مخطط اللون الخارجي المفضل لديك؟',
					cs_earthy_tones: ' ألوان الأرضية',
					cs_cool_tones: 'ألوان باردة',
					cs_warm_tones: 'ألوان دافئة',
					cs_white_bright: 'أبيض ومشرق',
					cs_monochromatic: 'أحادي اللون',
					cs_greys: 'ألوان رمادية',
				},
				primary_material: {
					title: 'الماده الاساسيه',
					text: 'ما هي المادة الأساسية التي تفضلها لتصميمك؟',
					pm_concrete: 'الخرسانة',
					pm_natural_stone: 'الحجر الطبيعي',
					pm_plain_paint: 'الطلاء العادي',
					pm_patterned_paint: 'الطلاء المنقوش',
					pm_metal_cladding: 'تكسية الواح معدنية',
					pm_aluminum_cladding: 'تكسية الواح ألومنيوم',
					pm_patterns_perforation: 'الواح نقوش وثقوب',
					pm_breeze_blocks: 'حجر مثقوب او منقوش',
					pm_wood: 'خشب',
					pm_glass: 'زجاج',
				},
				secondary_material: {
					title: 'الماده الثانويه',
					text: 'ما هي المادة الثانوية التي تفضلها لتصميمك؟',
					sm_concrete: 'الخرسانة',
					sm_natural_stone: 'الحجر الطبيعي',
					sm_plain_paint: 'الطلاء العادي',
					sm_patterned_paint: 'الطلاء المنقوش',
					sm_metal_cladding: 'تكسية الواح معدنية',
					sm_aluminum_cladding: 'تكسية الواح ألومنيوم',
					sm_patterns_perforation: 'الواح نقوش وثقوب',
					sm_breeze_blocks: 'حجر مثقوب او منقوش',
					sm_wood: 'خشب',
					sm_glass: 'زجاج',
				},
				surroundings: {
					title: 'المحيط',
					text: 'ما نوع البيئة المحيطة التي تفضلها؟',
					su_urban: 'منطقة حضرية',
					su_city_center: 'وسط المدينة',
					su_desert: 'منطقة صحراوية',
					su_farms: 'منطقة مزارع',
					su_tropical: 'منطقة استوائية',
					su_waterfront: 'الواجهة البحرية',
				},
				landscape: {
					title: 'العناصر الخاصة',
					text: 'ما نوع المناظر الطبيعية والعناصر الخاصة التي تفضلها؟',
					ls_pool_garden: 'حمام سباحة وحديقة',
					ls_rooftop_garden: 'حديقة على السطح',
					ls_exotic_garden: 'حديقة مميزة',
					ls_edible_garden: 'حديقة زراعية',
					ls_rocks_garden: 'حديقة من الصخور',
					ls_water_fountain: 'نافورة مياه',
				},
			},
			exterior_nav_items: [
				'البناء',
				'الطوابق',
				'التصميم',
				'اللون',
				'الماده الاساسيه',
				'الماده الثانويه',
				'المحيط',
				'العناصر الخاصة',
			],
		},
		menu_items: {
			home: 'الصفحة الرئيسية',
			applications: 'التطبيقات',
			results: 'النتائج',
			profile: 'الحساب',
			price: 'الاسعار',
			contact: 'اتصل بنا',
			logout: 'تسجيل خروج',
			login: 'تسجيل الدخول',
		},
		landing_general_text: {
			title_text:
				'Walls Design هي أداة متقدمة للذكاء الاصطناعي ، توفر إنشاء صورة سهلة وسريعة ودقيقة. سيساعدك الذكاء الاصطناعي في التصميم الخاص بنا على إضفاء الحيوية على أفكارك الأكثر جاذبية مع صور مذهلة.',
			take_me_button_text: 'خذني إلى التطبيق',
		},
		apps_text: {
			title: 'الذكاء الاصطناعي',
			sub_title:
				'تعمل هذه الأدوات كمساعدين مخصصين للتصميم. تحول تفضيلات التصميم الخاصة بك إلى صور بصرية رائعة وحصرية، مما يضمن أن المنتج النهائي الخاص بك فريد ومملوك لك فقط',
			interior_ai:
				'صمم المساحة الداخلية المثالية لك في ثوانٍ مع هذا الروبوت - من المطابخ وغرف المعيشة إلى الحمامات، الصالات الرياضية، وغيرها الكثير',
			comm_interior_ai:
				'حول مساحاتك التجارية بسرعة مع هذا البوت - سواء كانت مقاهي، متاجر تجزئة، مخابز، وغيرها الكثير',
			exterior_ai:
				'ارفع تصاميمك الخارجيةالى مستوى اخر في لحظة مع هذا الروبوت - من الفيلات والمباني السكنية إلى الأبراج الشاهقةوغيرها الكثير',
			comm_exterior_ai:
				'احدث ثوره في تصاميمك التجارية الخارجية بسرعة مع هذا البوت - شاملاً المستودعات، المباني الإدارية، المنشآت الحكومية، والكثير غيرها',
		},
		results_general_text: {
			select_choice: 'حدد أحد الخيارات',
			generate_button_text: 'ولّد الصوره',
			share_img_title: 'مشاركة رابط الصورة',
			share_img_message: 'انسخ وشارك رابط الصورة مع الآخرين',
			copy: 'نسخ',
			link_copied: 'تم نسخ الرابط',
			generating_your_img: '... توليد الصور الخاصة بك',
			invitation: 'أرسل لنا طلب عرض الأسعار',
			promp_text: {
				title: 'لا يمكن تغيير اللغة في هذه المرحلة',
				message:
					'يتطلب تغيير اللغة التنقل إلى صفحة البداية. من هناك ، يمكنك تغيير اللغة باستخدام الاختيارات الموجودة في أعلى الجانب الأيمن',
				question: 'هل تريد الانتقال إلى صفحة البداية؟',
				accept_button_text: 'نعم',
				reject_button_text: 'لا',
			},
			theatre_text: {
				title: 'هل يعجبك هذا التصميم',
				sub_title: 'يمكننا أن نبنيها لك',
				invitation: 'أرسل لنا بريدًا إلكترونيًا واترك الباقي لنا',
				quotation_request: 'طلب تسعير',
			},
		},
		profile_general_text: {
			profile: 'الحساب',
			details: 'التفاصيل',
			email: 'البريد الإلكتروني',
			name: 'الاسم',
			number_of_generations: 'عدد المحاولات',
			generations_remaining: 'عدد المحاولات المتبقية',
			unlimited: 'غير محدود',
			super_user: 'مستخدم استثنائي',
			yes: 'نعم',
			no: 'لا',
			generated_imgs: 'الصور المنتجه سابقاً',
			not_available: 'غير متوفر حاليا',
			theatre_text: {
				title: 'هل يعجبك هذا التصميم',
				sub_title: 'يمكننا أن نبنيها لك',
				invitation: 'أرسل لنا بريدًا إلكترونيًا واترك الباقي لنا',
				quotation_request: 'طلب تسعير',
			},
		},
		contact_general_text: {
			title: 'تواصل معنا',
			name: 'الاسم',
			email: 'البريد الإلكتروني',
			phone_number: 'رقم الهاتف',
			optional: 'اختياري',
			your_message: 'رسالتك',
			submit: 'رسالتك',
			contact_for: 'أنا أتصل من أجل',
			other: 'أخرى',
			purchase: 'الشراء',
			design: 'طلب تسعير',
			interested_in_design: 'أنا مهتم بهذا التصميم',
			room_size: '٥ x ٦',
			room_size_in_meters: 'حجم غرفتك بالمتر المربع',
			sending: 'نقوم بإرسال رسالتك',
			contact_success_text: {
				title: 'تم إرسال البريد الإلكتروني',
				message: 'سنعود إليك في أقرب وقت ممكن',
				app_btn_text: 'التطبيقات',
			},
			contact_cancel_text: {
				title: 'عذرا ، حدث خطأ ما',
				message: 'لا يمكن إرسال البريد الإلكتروني. الرجاء معاودة المحاولة في وقت لاحق',
				btn_text: 'اتصل بنا',
			},
		},
		pricing_text: {
			title: 'Pick up a payment plan to bring your wildest ideas to life with stunning visuals',
		},
		payment_success_text: {
			title: 'تم الدفع بنجاح',
			message: 'تم إرسال نسخة من إيصالك إلى بريدك الإلكتروني',
			app_btn_text: 'التطبيقات ',
		},
		payment_cancel_text: {
			title: 'تم إلغاء الدفع',
			message: 'هل تود أن تجرب مجددا',
			home_btn_text: 'الصفحة الرئيسية',
			btn_text: 'حاول مجددا',
		},
		login_text: {
			title: 'تسجيل الدخول ببيانات الاعتماد الخاصة بك',
			forgot_password: 'هل نسيت كلمة السر؟',
			login: 'تسجيل الدخول',
			dnt_have_account: 'ليس لديك حساب؟',
			signup: 'اشترك هنا',
			login_with: 'تسجيل الدخول مع',
		},
		signup_text: {
			title: 'إنشاء حساب',
			signup: 'اشتراك',
			alrdy_have_account: 'هل لديك حساب؟',
			login: 'تسجيل الدخول',
		},
		common: {
			style_example: 'أمثلة على طراز التصميم',
		},
	},
	EN: {
		interior_app: {
			interior_questions_data: {
				commercial_room_type: {
					title: 'SPACE TYPE',
					text: 'What space do you want to design?',
					retail_shop: 'Retail Shop',
					coffee_shop: 'Coffee Shop',
					supermarket: 'Supermarket',
					bakery: 'Bakery',
					barbershop: 'Barbershop',
					bookstore: 'Bookstore',
					fast_food_rest: 'Fast Food Restaurant',
					fine_dine_rest: 'Fine Dinning Restaurant',
					hair_nail_salon: 'Hair and Nail Salon',
					jewelry_store: 'Jewelry Store',
				},
				room_type: {
					title: 'ROOM TYPE',
					text: 'What space do you want to design?',
					living_room: 'Living Room',
					dining_room: 'Dining Room',
					bathroom: 'Bathroom',
					kitchen: 'Kitchen',
					bedroom: 'Bedroom',
					rooftop: 'Rooftop & Terraces',
					kids_room: 'Kids Room',
					home_office: 'Home Office',
					home_gym: 'Home Gym',
					home_cinema: 'Home Cinema',
				},
				design_style: {
					title: 'DESIGN STYLE',
					text: 'What is your preferred design style?',
					neo_classic: 'Neo Classic',
					contemporary: 'Contemporary',
					bohemian: 'Bohemian',
					minimal: 'Minimal',
					industrial: 'Industrial',
					rustic: 'Rustic',
					vintage: 'Vintage',
					modern: 'Modern',
					artdeco: 'Art Deco',
					mediterranean: 'Mediterranean',
				},
				general_mood: {
					title: 'GENERAL MOOD',
					text: 'What do you want your space to feel like?',
					cozy_warm: 'Cozy & Warm',
					peaceful_calm: 'Peaceful & Calm',
					detailed_sophisticated: 'Detailed & Sophisticated',
					vibrant_energetic: 'Vibrant & Energetic',
					calm_relaxing: 'Calm & Relaxing',
					spacious_open: 'Spacious & Open',
					traditional_comfortable: 'Traditional & Comfortable',
					artistic_inspirational: 'Artistic & Inspirational',
					modern_minimalistic: 'Modern & Minimalistic',
					rustic_natural: 'Rustic & Natural',
				},
				primary_color: {
					title: 'PRIMARY COLOR',
					text: 'What color do you want to see the most in your design?',
					prc_white: 'White',
					prc_grey: 'Greys',
					prc_beige: 'Beige & Tan',
					prc_navy: 'Navy Blue',
					prc_black: 'Black',
					prc_red: 'Reds',
					prc_olive_green: 'Olive Green',
					prc_pink: 'Pink',
					prc_terracotta: 'Terracotta',
					prc_dark_yellow: 'Dark Yellow',
					prc_mocha: 'Mocha',
					prc_purple: 'Purple',
				},
				secondary_color: {
					title: 'SECONDARY COLOR',
					text: 'What other color do you want to see the most in your design?',
					sec_white: 'White',
					sec_grey: 'Greys',
					sec_beige: 'Beige & Tan',
					sec_navy: 'Navy Blue',
					sec_black: 'Black',
					sec_red: 'Reds',
					sec_olive_green: 'Olive Green',
					sec_pink: 'Pink',
					sec_terracotta: 'Terracotta',
					sec_dark_yellow: 'Dark Yellow',
					sec_mocha: 'Mocha',
					sec_purple: 'Purple',
				},
				wall_cladding: {
					title: 'WALL CLADDING',
					text: 'What kind of wall finish do you prefer for your design?',
					wc_marble: 'Marble',
					wc_brick: 'Brick',
					wc_paint: 'Paint',
					wc_woodpanels: 'Wood Panels',
					wc_molding: 'French Molding',
					wc_microcement: 'Microcement',
					wc_wallpaper: 'Wallpaper',
					wc_stucco: 'Stucco',
				},
				flooring: {
					title: 'FLOORING',
					text: 'What flooring materials do want in your design?',
					fl_marble: 'Marble',
					fl_vinyl: 'Vinyl',
					fl_parquet: 'Parquet',
					fl_tiles: 'Tiles',
					fl_carpet: 'Carpet',
					fl_granite: 'Granite',
					fl_porcelain: 'Porcelain',
					fl_microcement: 'Microcement',
				},
				art_work: {
					title: 'ART WORK',
					text: 'What art style fits best in your design?',
					art_realistic: 'Realistic',
					art_bold: 'Bold',
					art_simple: 'Simple',
					art_abstract: 'Abstract',
					art_impressionist: 'Impressionist',
					art_cubist: 'Cubist',
					art_pop: 'Pop',
					art_contemporary: 'Contemporary',
				},
			},
			interior_nav_items: [
				'Room',
				'Design',
				'Mood',
				'Primary Color',
				'Secondary Color',
				'Cladding',
				'Flooring',
				'Art',
			],
		},
		exterior_app: {
			exterior_questions_data: {
				commercial_building_type: {
					title: 'BUILDING TYPE',
					text: 'What type of building do you want to design?',
					cbt_industrial: 'Industrial Warehouse',
					cbt_skyscraper: 'Skyscraper',
					cbt_office_tower: 'Office Tower',
					cbt_retail_complex: 'Retail Complex',
					cbt_municipal_building: 'Municipal Building',
					cbt_governmental_building: 'Governmental Building',
					cbt_hospital_building: 'Hospital Building',
					cbt_mixed_building: 'Mixed-Use Building',
				},
				building_type: {
					title: 'BUILDING TYPE',
					text: 'What type of building do you want to design?',
					bt_single_family_residence: 'Single Family Residence',
					bt_apartments_building: 'Apartments Building',
					bt_private_chalet: 'Private Chalet',
					bt_highrise_residential_building: 'Highrise Residential Building',
					bt_farm_house: 'Farm House',
					bt_hotel: 'hotel',
				},
				floors_number: {
					title: 'FLOORS NUMBER',
					text: 'What is the number of floors you want in your building?',
					fn_one_two: '1 to 2 Floors',
					fn_two_three: '2 to 3 Floors',
					fn_three_four: '3 to 4 Floors',
					fn_four_five: '4 to 5 Floors',
					fn_five_six: '5 to 6 Floors',
					fn_more_six: 'More than 6 Floors',
				},
				arch_style: {
					title: 'ARCHITECURAL STYLE',
					text: 'What is your preferred architectural style?',
					as_neo_futurist: 'Neo Futurist',
					as_neo_classical: 'Neo Classical',
					as_high_tech: 'High Tech',
					as_contemporary: 'Contemporary',
					as_minimal: 'Minimal',
					as_modern: 'Modern',
				},
				color_scheme: {
					title: 'COLOR SCHEME',
					text: 'What is your preferred exterior color scheme?',
					cs_earthy_tones: 'Earthy Tones',
					cs_cool_tones: 'Cool Tones',
					cs_warm_tones: 'Warm Tones',
					cs_white_bright: 'White and Bright',
					cs_monochromatic: 'Monochromatic',
					cs_greys: 'Greys',
				},
				primary_material: {
					title: 'PRIMARY MATERIAL',
					text: 'What is the primary material you prefer for your design?',
					pm_concrete: 'Concrete',
					pm_natural_stone: 'Natural Stone',
					pm_plain_paint: 'Plain Paint',
					pm_patterned_paint: 'Patterned Paint',
					pm_metal_cladding: 'Metal Cladding',
					pm_aluminum_cladding: 'Aluminum Cladding',
					pm_patterns_perforation: 'Patterns & Perforation',
					pm_breeze_blocks: 'Breeze Blocks',
					pm_wood: 'Wood',
					pm_glass: 'Glass',
				},
				secondary_material: {
					title: 'SECONDARY MATERIAL',
					text: 'What is the Secondary material you prefer for your design?',
					sm_concrete: 'Concrete',
					sm_natural_stone: 'Natural Stone',
					sm_plain_paint: 'Plain Paint',
					sm_patterned_paint: 'Patterned Paint',
					sm_metal_cladding: 'Metal Cladding',
					sm_aluminum_cladding: 'Aluminum Cladding',
					sm_patterns_perforation: 'Patterns & Perforation',
					sm_breeze_blocks: 'Breeze Blocks',
					sm_wood: 'Wood',
					sm_glass: 'Glass',
				},
				surroundings: {
					title: 'SURROUNDINGS',
					text: 'What kind of surroundings do you prefer for your building?',
					su_urban: 'Urban',
					su_city_center: 'City Center',
					su_desert: 'Desert',
					su_farms: 'Farms',
					su_tropical: 'Tropical',
					su_waterfront: 'Waterfront',
				},
				landscape: {
					title: 'LANDSCAPE',
					text: 'What type of landscaping and special elements do you prefer for your building?',
					ls_pool_garden: 'Pool and Garden',
					ls_rooftop_garden: 'Roof Top Garden',
					ls_exotic_garden: 'Exotic Garden',
					ls_edible_garden: 'Edible Garden',
					ls_rocks_garden: 'Rocks Garden',
					ls_water_fountain: 'Water Fountain',
				},
			},
			exterior_nav_items: [
				'Building',
				'Floors',
				'Style',
				'Color',
				'Primary Material',
				'Secondary Material',
				'Surroundings',
				'Landscape',
			],
		},
		menu_items: {
			home: 'Home',
			applications: 'Applications',
			results: 'Results',
			profile: 'Profile',
			price: 'Pricing',
			contact: 'Contact us',
			logout: 'Logout',
			login: 'Login',
		},
		landing_general_text: {
			title_text:
				'Walls Design is an advanced AI tool, providing an easy, fast and accurate image generation. Our design AI will help you bring your wildest ideas to life with stunning visuals.',
			take_me_button_text: 'Take me to the App',
		},
		apps_text: {
			title: 'AI',
			sub_title:
				'These tools serve as personalized design assistants. It transforms your design preferences into exclusive, visually compelling images, guaranteeing that your final product is uniquely distinctive and solely your own.',
			interior_ai:
				'Craft your ideal interior space in mere seconds with this Bot – from kitchens and living rooms to bathrooms, gyms, and beyond',
			comm_interior_ai:
				'Transform your commercial spaces swiftly with this Bot – be it coffee shops, retail stores, bakeries, and so much more.',
			exterior_ai:
				'Elevate your exterior designs in an instant with this Bot – from villas and apartment buildings to towering skyscrapers and beyond.',
			comm_exterior_ai:
				'Revolutionize your commercial exteriors swiftly with this Bot – encompassing warehouses, office buildings, governmental structures, and so much more.',
		},
		results_general_text: {
			select_choice: 'Select a choice',
			generate_button_text: 'Generate image',
			share_img_title: 'Share image link',
			share_img_message: 'Copy and share the image link with others',
			copy: 'Copy',
			link_copied: 'Link copied',
			generating_your_img: 'Generating your image ...',
			invitation: 'Send us quotation request.',
			promp_text: {
				title: 'The language cannot be changed at this stage',
				message:
					'Changing language, requires a navigation to the start page. From there, you can change the language using the choices on top right side.',
				question: 'Do you want to navigate to the start page?',
				accept_button_text: 'Yes',
				reject_button_text: 'No',
			},
			theatre_text: {
				title: 'Do you like this design!',
				sub_title: 'We can build it for you.',
				invitation: 'Send us an Email, and leave the rest for us.',
				quotation_request: 'Quotation Request',
			},
		},
		profile_general_text: {
			profile: 'Profile',
			details: 'Details',
			email: 'Email',
			name: 'Name',
			number_of_generations: 'Number of generations',
			generations_remaining: 'Generations remaining',
			unlimited: 'Unlimited',
			super_user: 'Super user',
			yes: 'Yes',
			no: 'No',
			generated_imgs: 'Generated images',
			not_available: 'Not available at the moment',
			theatre_text: {
				title: 'Do you like this design!',
				sub_title: 'We can build it for you.',
				invitation: 'Send us an Email, and leave the rest for us.',
				quotation_request: 'Quotation Request',
			},
		},
		contact_general_text: {
			title: 'Get in touch',
			name: 'Name',
			email: 'Email',
			phone_number: 'Phone number',
			optional: 'Optional',
			your_message: 'Your message',
			submit: 'Submit',
			contact_for: 'I am contacting for',
			other: 'Other',
			purchase: 'Purchase',
			design: 'Quotation Request',
			interested_in_design: 'I am interested in this design',
			room_size: '5 x 6',
			room_size_in_meters: 'Your room size in square meters',
			sending: 'Sending your message ...',
			contact_success_text: {
				title: 'Email sent.',
				message: 'We will get back to you as soon as possible',
				btn_text: 'Applications',
			},
			contact_cancel_text: {
				title: 'Sorry, something went wrong!',
				message: 'The email could not be sent. Please try again later.',
				btn_text: 'Contact Us',
			},
		},
		pricing_text: {
			title: 'Pick up a payment plan to bring your wildest ideas to life with stunning visuals',
		},
		payment_success_text: {
			title: 'Payment Succesful!',
			message: 'A copy of your receipt has been sent to your email',
			app_btn_text: 'Applications',
		},
		payment_cancel_text: {
			title: 'Payment Cancelled!',
			message: 'Would you like to try again?',
			btn_text: 'Try Again',
		},
		login_text: {
			title: 'Login with your account',
			forgot_password: 'Forgot Password?',
			login: 'Login',
			dnt_have_account: "Don't have an account?",
			signup: 'Sing Up',
			login_with: 'LOGIN WITH',
		},
		signup_text: {
			title: 'Create an account',
			signup: 'Signup',
			alrdy_have_account: 'Already have an account?',
			login: 'Login',
		},
		common: {
			style_example: 'Design style examples',
		},
	},
};
